.principal-processing-payment-container {
  height: 100%;
  width: 100%;
  background-color: #f9f9f9;
  padding: 50px 0px;
}

.processing-payment-container {
  min-height: 730px;
}

.processing-payment-content-arrow {
  margin-bottom: -25px;
}

.processing-payment-arrow-back {
  margin-left: 10%;
}

.processing-payment-content-img {
  margin-bottom: 20px;
  text-align: center;
}

.processing-payment-content-title {
  text-align: center;
}

.processing-payment-content-title p {
  color: #434362;
  font-family: "Roboto";
  font-size: 25px;
  font-weight: bold;
  line-height: 32px;
}
.processing-payment-content-title p>span {
  background: -webkit-linear-gradient(top, transparent 60%, #fdd44b 40%);
}

.processing-payment-content-text-mark {
  margin: -48px -35px 60px 223px;
}

.processing-payment-text-mark {
  width: 110px;
  height: 17px;
  background-color: #fdd44b;
  margin-top: -15px;
  z-index: 1;
  margin: 0px auto;
}

.processing-payment-content-email {
  text-align: center;
  margin: 20px 0px 30px 0px;
}

.processing-payment-content-subtitle {
  text-align: center;
  margin: 20px 0px 30px 0px;
}

.processing-payment-content-subtitle p {
  color: #434362;
  font-family: "Roboto";
  font-size: 14px;
  font-weight: normal;
  line-height: 22px;
}

.processing-payment-content-subtitle p span {
  color: #434362;
  font-family: "Roboto";
  font-size: 14px;
  font-weight: bold;
  line-height: 20px;
}

.processing-payment-form-container {
  margin: 40px 13%;
}

.processing-payment-button-container {
  margin: 40px 0px 0px 0px;
}

.processing-payment-content-footer {
  display: flex;
  justify-content: center;
  margin-top: 25px;
  align-items: center;
}

.processing-payment-footer-img {
  height: 24px;
  width: 124px;
}

/* Ipad */

@media (max-width: 769px) {
  .principal-processing-payment-container {
    text-align: center;
  }
  .processing-payment-container {
    background-image: none;
    background-color: #f9f9f9;
    height: auto;
    min-height: 650px;
    padding-bottom: 50px;
    padding-top: 0px;
  }
  .processing-payment-content-img {
    margin-bottom: 40px;
    text-align: center;
  }
  .processing-payment-content-title {
    text-align: center;
  }
  .processing-payment-content-title p {
    color: #434362;
    font-family: "Roboto";
    font-size: 25px;
    font-weight: bold;
    line-height: 32px;
  }
  .processing-payment-content-text-mark {
    margin: -17px 0px 25px 0px;
  }
  .processing-payment-text-mark {
    width: 120px;
    height: 17px;
    background-color: #fdd44b;
    margin: 0px auto;
  }
  .processing-payment-content-spinner {
    text-align: center;
    margin: 20px 0px 30px 0px;
  }
  .processing-payment-content-text {
    text-align: center;
    margin-top: 20px;
    margin-bottom: 0px;
  }
  .processing-payment-content-text p {
    color: #434362;
    font-family: "Roboto";
    font-size: 15px;
    font-weight: bold;
    line-height: 20px;
  }
  .processing-payment-content-subtitle {
    text-align: center;
    margin-top: 8px;
    margin-bottom: 50px;
  }
  .processing-payment-content-subtitle p {
    color: #434362;
    font-family: "Roboto";
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
  }
  .processing-payment-form-container {
    margin: 0 20%;
  }
  .processing-payment-button-container {
    margin: 60px 10%;
  }
  .processing-payment-content-footer {
    display: flex;
    justify-content: center;
    margin-top: 25px;
    align-items: center;
  }
  .processing-payment-footer-img {
    height: 24px;
    width: 124px;
  }
}

/* Mobile */

@media (max-width: 400px) {
  .processing-payment-container {
    padding-top: 0px;
  }
  .processing-payment-button-container {
    margin: 60px 0px 40px 0px;
  }
  .processing-payment-form-container {
    margin: 0 10%;
  }
}