.confirm-remove-additional-user-dialog-content {
    padding: 30px;
    text-align: center;
    font-family: 'Roboto';
    max-width: 400px;
}

.confirm-remove-additional-user-dialog .close-icon {
    margin: 15xp;
    cursor: pointer;
}

.confirm-remove-additional-user-dialog-title {
    font-weight: bold;
    max-width: 270px;
    margin: auto;
}

.confirm-remove-additional-user-dialog-subtitle {
    font-weight: 400;
    max-width: 270px;
    line-height: 25px;
    margin: auto;
}

.confirm-remove-additional-user-dialog-button-container {
    padding-top: 40px;
}

.confirm-remove-additional-user-dialog-button-container button {
    margin-bottom: 10px;
}

@media (max-width: 768px) {
    .confirm-remove-additional-user-dialog-content {
        padding: 30px;
    }
}