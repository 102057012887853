.edit-paypal-container img{
  height: 30px;
  margin-bottom: 10px;
}

.edit-paypal-container p{
  color: #434362;
  font-size: 14px;
}

.edit-paypal-container button{
  border: none;
  background-color: #B9D1DB;
  color: #2C5D6E;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}