.additional-services-section {
  margin-bottom: 90px;
}

.additional-services-section-title {
  color: #434362;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 30px;
}

.additional-services-section-title span {
  font-weight: normal;
}

.additional-service-container {
  height: 46px;
  cursor: pointer;
  text-align: center;
  background: -webkit-linear-gradient(left, #DCE4EF 3%, #EDF1F7 3%);
  border-radius: 5px;
  -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
  display: flex;
  align-items: center;
}

.additional-service-container:hover {
  background: #E4EAF4;
}

.additional-service-container span {
  color: #434362;
  font-size: 13px;
  font-weight: bold;
  margin-left: 30px;
}

.additional-service-container img {
  height: 28px;
  margin-left: 30px;
}

@media (max-width: 769px) {
  .additional-services-section {
    padding: 0px 20px;
  }
}