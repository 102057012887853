.blog-detail-container {
  background-color: #ffffff;
  padding: 40px 60px;
  border-radius: 5px;
}
.blog-detail-photo {
  margin-bottom: 10px;
  height: 250px;
  border-radius: 5px;
  position: relative;
  overflow: hidden;
}
.blog-detail-photo img {
  width: 100%;
  position: absolute;
  top: -9999px;
  bottom: -9999px;
  left: -9999px;
  right: -9999px;
  margin: auto;
}

.blog-detail-title {
  font-size: 30px;
  font-weight: bold;
  color: #434362;
  margin-bottom: 20px;
  text-align: left;
}
.blog-detail-action-container{
    display: flex;
    margin-bottom: 20px;
    justify-content: flex-end;
}
.blog-detail-action {
  background-color: #f0f1f4;
  border-radius: 5px;
  margin-left: 10px;
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  cursor: pointer;
}

.blog-detail-action-title{
    margin: 0px 5px;
}
.blog-detail-action-counter {
  font-size: 8px;
  align-self: flex-end;
  margin-bottom: 2px;
}

.blog-detail-text {
  color: #434362;
  text-align: justify;
}

.blog-detail-additional-title{
    color: #434362;
    font-weight: 600;
    font-size: 22px;
    margin: 60px 0 30px 0;
}

@media (max-width: 769px) {
  .blog-detail-container {
    padding: 25px;
  }

  .blog-detail-additional-container{
    padding: 25px;
  }
}
