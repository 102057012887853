.principal-doubts-organism {
  margin-top: 90px;
  background-color: rgba(249, 249, 249, 1);
}

.wrap-doubts-organism .content-title .title {
  font-style: normal;
  font-weight: bolder;
  font-size: 35px;
  color: #434362;

  margin-bottom: 24px;
  line-height: 40px;
  text-align: center;
}

.wrap-doubts-organism .content-subtitle .subtitle {
  color: #434362;
  font-size: 16px;
  font-weight: 300;
  line-height: 25px;
  margin: auto;
  margin-bottom: 60px;
  text-align: center;
  max-width: 435px;
}

.wrap-doubts-organism>.wrap-questions {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 40px;
}

.wrap-doubts-organism .wrap-questions .animated.fadeOut{
  max-height: 0;
  -webkit-transition: max-height  1s;
  -moz-transition: max-height  1s;
  -o-transition: max-height  1s;
  transition: max-height  1s;
}
.wrap-doubts-organism .wrap-questions .animated.fadeIn{
  max-height: 1000px;
}

.wrap-doubts-organism>.wrap-questions>div {
  width: 100%;
  max-width: 790px;
}

.wrap-doubts-organism .footer {
  font-style: normal;
  font-weight: bolder;
  font-size: 16px;
  color: #57578e;
  cursor: pointer;
  text-align: center;
}

.wrap-doubts-organism>.content-button {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}

@media (max-width: 768px) {
  .wrap-doubts-organism>.wrap-questions>.content-questions {
    width: 100%;
    max-width: 500px;
  }
  .wrap-doubts-organism>.content-title {
    margin-top: 15px;
  }
  .wrap-doubts-organism>.content-title .title {
    margin-bottom: 18px;
    font-weight: bold;
    letter-spacing: 1px;
    line-height: 30px;
    color: #434362;
    text-align: center;
  }
  .wrap-doubts-organism>.content-subtitle {
    margin-bottom: 35px;
    margin-top: 20px;
  }
  .wrap-doubts-organism>.content-subtitle>.subtitle {
    font-size: 16px;
    line-height: 24px;
    color: #434362;
    font-weight: normal;
    text-align: center;
  }
  .wrap-doubts-organism>.wrap-alert-message-molecules {
    margin-bottom: 50px !important;
  }
  .wrap-doubts-organism>.footer {
    font-size: 15px;
    font-weight: bold;
    line-height: 20px;
    margin-bottom: 40px;
    text-align: center;
  }
  .wrap-doubts-organism>.content-button {
    margin-top: 0px;
    margin-bottom: -20px;
  }
  .wrap-doubts-organism>.content-button .button_atom {
    height: 40px !important;
    width: 80% !important;
    max-width: 429px !important;
  }
  .wrap-doubts-organism>.wrap-questions>div {
    width: auto;
  }
}