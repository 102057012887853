.calendar-section {
  color: #646B72;
  max-width: 85%;
  margin: auto;
}

.calendar-section .calendar-title {
  border-bottom: 1px solid #E8E8E8;
}

.calendar-section .calendar-title .next-month {
  padding: 10px;
  cursor: pointer;
}

.calendar-section .calendar-title .current-month {
  padding: 10px;
  font-size: 20px;
  font-weight: bold;
}

.calendar-section .calendar-title .prev-month {
  padding: 10px;
  cursor: pointer;
}

.calendar-section .day-name-title {
  margin: 10px 5px;
  padding: 5px;
  border-radius: 25px;
  background-color: #F2F2F2;
  font-weight: bold;
}

.calendar-section .day-number {
  margin: 4px 0px;
  padding: 5px;
}

.calendar-section .day-number {
  border-radius: 5px;
  min-width: 20px;
  text-align: center;
}

.calendar-section .day-number.today {
  box-shadow: 0 0 0 3px #5A549D;
  border-radius: 5px;
}

.calendar-section .calendar-footer {
  padding: 20px;
}

.calendar-section .rule-description-container {
  text-align: center;
  display: flex;
  margin: 5px 0;
  align-items: center;
  justify-content: center;
}

.calendar-section .rule-description-container .rule-color {
  height: 20px;
  width: 20px;
  border-radius: 5px;
  display: inline-block;
  margin-right: 10px;
}

.calendar-section .rule-description-container .rule-description {
  color: #434362;
  font-size: 11px;
  max-width: 150px;
}

@media (max-width: 769px) {
  .calendar-section {
    max-width: 100%;
  }
  .calendar-section .calendar-footer{
    padding: 20px 0;
  }
  .calendar-section .calendar-title .current-month {
    font-size: 18px;
  }
}