.blog-page .container{
  max-width: 750px !important;
}

.blog-input-container {
  max-width: 450px;
  margin: auto;
  margin-top: 50px;

}

.blog-articles-container {
  margin-top: 50px;
}

.blog-category {
  background-color: #F0F1F4;
  color: #434362;
  font-size: 14px;
  border-radius: 5px;
  padding: 10px 30px;
  cursor: pointer;
}

@media (max-width: 769px) {
  .blog-articles-container {
   margin-left: 25px;
   margin-right: 25px;
  }
  .blog-category {
    margin-bottom: 10px;
  }

}