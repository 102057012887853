.config-notifications-container {
  max-width: 700px;
  margin: 0 auto 60px auto;
}

.config-notifications-container .header-message {
  background-color: #E6EFF1;
  padding: 20px 30px;
  color: #258498;
  font-size: 14px;
  margin-bottom: 10px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.config-notifications-container .header-message-icon{
  margin-right: 10px;
}

.config-notifications-container .footer-message {
  font-size: 10px;
  color: #707C89;
  max-width: 400px;
  text-align: center;
  line-height: 22px;
  margin: 20px auto;
}

.config-notifications-container .main-panel {
  padding: 20px;
  background-color: #fff;
}

.config-notifications-container .notification-container {
  padding: 10px;
  color: #434362;
  font-size: 12px;
  border-bottom: 1px solid #EDEDED;
}

.config-notifications-container .notification-container:first-child {
  border-bottom: none;
}

.config-notifications-container .notification-container:last-child {
  border-bottom: none;
}

.config-notifications-container .notification-container .title {
  color: #258498;
}

.config-notifications-container .notification-container .none-option {
  color: #AFAFAF;
}

.config-notifications-container .notification-container .none-option .icon {
  color: #AFAFAF;
}

.config-notifications-container .notification-container .none-option .icon.selected {
  color: #B52260;
}

.config-notifications-container .notification-container .icon {
  color: #258498;
  cursor: pointer;
}

.config-notifications-button-container {
  text-align: center;
}

@media (max-width: 769px) {
  .config-notifications-container{
    margin: 10px;
  }
  .config-notifications-container .header-message{
    /* max-width: 250px; */
    margin: 0 20px;
    padding: 20px;
  }
  .config-notifications-container .notification-container p{
    text-align: center;
    width: 100%;
    margin-bottom: 10px;
  }
  .config-notifications-container .notification-container p.label{
    margin-bottom: 30px;
  }
  .config-notifications-container .notification-container p.title{
    font-size: 9px;
  }
  .config-notifications-container .main-panel{
    background-color: transparent;
  }
  .config-notifications-container .notification-container{
    border: solid 1px #EFEFEF;
    background-color: #fff;
    border-radius: 5px;
    margin-bottom: 10px;
    padding: 30px;
  }
  .config-notifications-button-container {
    text-align: center;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    margin: 0;
    padding: 15px 0;
    background-color: #f9f9f9;
  }
  
  .config-notifications-button-container button {
    margin: 0px 20px;
    width: -webkit-fill-available;
  }
}