.insure-loading-container {
  text-align: center;
}

.insure-loading-container .loading-icon {
  margin: 20px;
  color: #646B72;
}

.insure-loading-container .title {
  padding: 0 20px;
  font-weight: normal;
}
