.payment-alerta-plates-container {
  padding: 15px;
  border-radius: 5px;
  background-color: #f7f7f8;
  margin: 0 -15px;
}

.plates-estate-container {
  margin-bottom: 30px;
}
.plates-estate-container:last-child {
  margin-bottom: 0px;
}

.plates-estate-title {
  color: #434362;
  font-size: 12px;
  margin-bottom: 10px;
}

.plates-success-icon {
  color: #17788d;
}

.plates-text {
  color: #434362;
  font-size: 14px;
  font-weight: bold;
  margin-left: 5px;
}

