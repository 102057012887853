.service-side-section {
  width: 100%;
}
.service-side-section .title {
  color: #646B72;
  font-size: 10px;
  margin: 5px 0;
}

.service-side-section .tag {
  color: #fff;
  font-size: 10px;
  background-color: #F259AF;
  padding: 6px;
  margin: 10px 0 5px 0;
  border-radius: 5px;
  text-align: center;
}

.service-side-section .side-label {
  color: #9A9A9A;
  font-size: 10px;
  margin-right: 5px;
  font-weight: 500;
}

.service-side-section .side-variable {
  color: #17788D;
  font-size: 10px;
}

.service-side-section .side-variable.alert {
  color: #D60013;
}

.service-side-section .side-variable .icon{
  margin-left: 5px;
}

.service-side-section .loading-container .title{
  color: #17788D;
  font-size: 11px;
}

.service-side-section .loading-container .loading{
  text-align: center;
  margin-top: 15px;
}

.service-side-section .loading-container .loading-icon{
  color: #17788D;
  margin: auto;
}

.service-side-section .without-info-container .title{
  margin-top: 15px;
  color: #17788D !important;
  font-size: 11px !important;
  line-height: 15px;
}