.legals-container {
  padding: 20px;
  background-color: #efefef;
  border-radius: 5px;
  margin: 10px 0;
  color: #52526f;
}

.legals-container p {
  font-size: 12px;
}
