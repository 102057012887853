.box-question-molecules {
  /* filter: drop-shadow(0px 3px 13px rgba(0, 0, 0, 0.161)); */
  background-color: #ffffff !important;
  border-radius: 5px;
  box-shadow: 0px 0px 10px 2px #e7e7e794;
  margin-bottom: 22px;
  padding: 25px 45px 25px 70px;
  text-align: left;
  cursor: pointer;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  max-width: 790px;
}

.box-show {
  max-height: 200px;
  background-color: rgba(247, 247, 247, 1);
}

.box-question-molecules > .content-title {
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #434362;
  line-height: 21px;
}

.box-question-molecules > .content-title > img {
  width: 24px;
  height: auto;
}

.box-question-molecules > .content-subtitle {
  margin-top: 15px;
  width: 100%;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  color: #707c89;
  max-width: 595px;
}

@media (max-width: 1060px) {
  .box-question-molecules > .content-title > img {
    width: 20px;
    height: auto;
  }
  .box-question-molecules > .content-subtitle {
    font-size: 13px;
    line-height: 18px;
    margin-top: 13px;
  }
}

@media (max-width: 700px) {
  .box-question-molecules {
    padding: 29px 20px 29px 20px;
  }

  .box-question-molecules > .content-title > img {
    width: 20px;
    height: auto;
  }

  .box-question-molecules > .content-title {
    font-weight: bold;
    font-size: 15px;
    color: #434362;
    line-height: 20px;
  }

  .box-question-molecules > .content-subtitle {
    font-size: 13px;
    line-height: 20px;
    margin-top: 13px;
    font-weight: normal;
  }
}
