.home-additional-services-section {
  margin-bottom: 170px;
}

.home-additional-services-section .title {
  color: #434362;
  font-size: 35px;
  font-weight: bold;
  margin-bottom: 40px;
  max-width: 400px;
}

.home-additional-services-section .service-container {
  background-color: transparent;
  box-shadow: none;
  content: none;
}

.home-additional-services-section .service-container::before {
  content: none;
}

.home-additional-services-section .service-title {
  flex-direction: row-reverse;
}

.home-additional-services-section .service-title .service-action-icon {
  color: #1A93B1;
}

.home-additional-services-section .service-title .MuiAccordionSummary-expandIconWrapper.Mui-expanded {
  transform: none;
}

.home-additional-services-section .service-title p {
  margin-left: 20px;
  color: #434362;
}

.home-additional-services-section .service-details {
  padding: 0px 0px 0px 55px;
}

.home-additional-services-section .service-details p {
  color: #434362;
  max-width: 300px;
  font-weight: 300;
  font-size: 13px;
}

.home-additional-services-section .animated.fadeOutDown {
  max-height: 0;
  -webkit-transition: max-height 1s;
  -moz-transition: max-height 1s;
  -o-transition: max-height 1s;
  transition: max-height 1s;
}

.home-additional-services-section .animated.fadeInUp {
  max-height: 1000px;
}

.home-additional-services-section .show-more {
  color: #1A93B1;
  font-size: 13px;
  margin: 20px 0 0 20px;
  cursor: pointer;
}

@media (max-width: 768px) {
  .home-additional-services-section .title {
    text-align: center;
  }

  .home-additional-services-section>div {
    flex-direction: column-reverse;
  }

  .home-additional-services-section img {
    width: 100%;
    margin-top: 60px;
  }

  .home-additional-services-section {
    margin-bottom: 90px;
  }
}