.premium-report-container {
  margin-top: 30px;
}

.premium-report-service-container {
  margin-bottom: 10px;
  padding: 10px;
  background-color: #eeeef5;
  border-radius: 5px;
}

.premium-report-service-image {
  height: 25px;
}

.premium-report-service-name {
  color: #646b72;
  font-weight: bold;
  font-size: 14px;
}

.premium-report-service-icon-success {
  color: #17788d;
}

.premium-report-service-icon-danger {
  color: #e08686;
}

.premium-service-title {
  background-color: #f5f5f5;
  margin-bottom: 10px;
  padding: 10px;
  padding-left: 55px;
  border-radius: 5px;
  color: #646b72;
  font-weight: bold;
  font-size: 14px;
}

.premium-service-section {
  padding: 10px 20px 10px 55px;
  margin-bottom: 10px;
  border-radius: 5px;
  border: solid 1px #dbdbdb;
}

.premium-service-subtitle {
  color: #6c6c6c;
  font-size: 14px;
  font-weight: bold;
}

.premium-service-title.collapsable,
.premium-service-subtitle.collapsable {
  cursor: pointer;
}

.premium-service-title .icon,
.premium-service-subtitle .icon {
  float: right;
  color: #bcbcbc;
}

.premium-service-section-container {
  padding-top: 20px;
  margin-right: 40px;
}

.premium-service-section-animate .animated.fadeOut {
  max-height: 0;
  /* -webkit-transition: max-height 1s;
  -moz-transition: max-height 1s;
  -o-transition: max-height 1s;
  transition: max-height 1s; */
}

.premium-service-section-animate .animated.fadeIn {
  max-height: 2000px;
}

.fotocivicas-section.premium {
  margin-top: 20px;
  margin-left: 20px;
}

.fotocivicas-section.premium .title {
  flex-direction: column;
  align-items: flex-start;
  margin-top: 0px;
}

.fotocivicas-section.premium .title .label {
  margin-bottom: 20px;
  font-size: 14px;
  font-weight: bold;
}

.fotocivicas-section.premium .current-points .label,
.fotocivicas-section.premium .current-points .text,
.fotocivicas-section.premium .current-points .status {
  text-align: left;
}
.fotocivicas-section.premium .points-footer {
  text-align: left;
  margin: 20px 0;
  max-width: none;
}

.circulation-card-section.premium {
  margin-top: 10px;
  margin-right: 80px;
}

.circulation-card-section.premium .main-label.premium {
  font-size: 14px;
  font-weight: bold;
}
