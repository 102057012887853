.link-share {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 5px;
  margin: 0px;
  cursor: pointer;
}
.link-share span{
  display: flex;
  align-items: baseline;
}

.link-share img {
  width: 13px;
  height: 17px;
  margin-right: 7px;
}

.link-share span {
  color: #5a549f;
  font-family: "Roboto";
  font-size: 13px;
  font-weight: bold;
  line-height: 18px;
  padding-right: 4px;
  padding-bottom: 3px;
}