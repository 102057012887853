.principal-services-section .principal-services-data-section {
  margin: 20px 0;
}

.principal-services-section .title {
  font-size: 13px;
  color: #646B72;
  font-weight: bold;
}

.principal-services-section .side-service-container {
  cursor: pointer;
}

.principal-services-section .card {
  width: 100%;
  height: 110px;
  text-align: center;
  border-radius: 5px;
  background-color: #F8F8F8;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
}

.principal-services-section .card.selected {
  background-color: #FFF;
  box-shadow: 0 0 0 2px #228397;
}

.principal-services-section .card img {
  display: block;
  margin: 0px auto;
  margin-bottom: 10px;
  height: 40px;
  max-width: 50px;
}

.principal-services-section .card span {
  display: block;
  color: #434362;
  font-size: 13px;
  font-weight: bold;
}

.principal-services-section .side-component {
  height: 90px;
  width: 100%;
  border-radius: 5px;
  box-shadow: 0 0 0 1px #E3E3E3;
  padding: 10px 15px;
}

.principal-services-section .side-component.selected {
  box-shadow: 0 0 0 2px #228397;
}

.principal-services-section .side-component .title {
  color: #646B72;
  font-weight: bold;
  font-size: 12px;
}

.principal-services-section .service-selected {
  width: 100%;
  border-radius: 5px;
  box-shadow: 0 0 0 1px #E3E3E3;
  padding: 20px;
}

.principal-services-section .show-more-container {
  text-align: center;
  color: #4B4C6F;
  font-size: 10px;
  cursor: pointer;
  margin-top: 20px;
}

.principal-services-section .show-more-container span {
  display: block;
}

.principal-services-section .show-more-container .icon {
  margin: 5px;
}

.principal-services-section .principal-services-loading-container {
  text-align: center;
  margin: 10px 0;
  color: #5A549D;
}

.principal-services-section .principal-services-loading-container span {
  color: #5A549D;
}

@media (max-width: 769px) {
  .principal-services-section .service-selected {
    padding: 10px;
  }

  .service-main-section .service-main-content {
    padding: 20px !important;
  }

  .service-main-section .content-section {
    flex-direction: column;
  }

  .service-main-section .main-variable {
    text-align: left !important;
    margin: 10px 0;
  }

  .service-main-section .main-plate {
    width: fit-content;
    margin: 5px 0;
  }
  .principal-services-section .side-component {
    padding-right: 10px;
  }
}