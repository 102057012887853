 .content-header {
   margin-top: 5px;
   padding: 30px 14% 0px 14%;
 }

 .content-header-arrow {
   position: absolute;
 }

 .content-header-arrow img {
   cursor: pointer;
 }

 .content-header-title {
   text-align: center;
   margin-bottom: 30px;
 }

 .content-header-title p {
   color: #434362;
   font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
   font-size: 25px;
   font-weight: bold;
   line-height: 32px;
   max-width: 500px;
   margin: auto;
 }

 .content-header-title p>span {
   background: -webkit-linear-gradient(top, transparent 60%, #fdd44b 40%);
 }

 .content-header-subtitle {
   text-align: center;
   margin-bottom: 20px;
 }

 .content-header-subtitle p {
   color: #434362;
   font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
   font-size: 14px;
   font-weight: normal;
   line-height: 22px;
   max-width: 450px;
   margin: auto;
 }

 .content-header-subtitle p>span {
   color: #434362;
   font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
   font-size: 14px;
   font-weight: bold;
   line-height: 20px;
 }

 @media (max-width: 768px) {
   .content-header-arrow {
     display: none;
   }
   .content-header-subtitle {
     margin: auto;
     margin-bottom: 40px;
     max-width: 265px;
   }
 }