
.payment-type-section{
  display: flex;
  justify-content: space-between;
  background-color: #e9f0f2;
  border-radius: 5px;
  padding: 15px 30px;
  align-items: center;
  cursor: pointer;
  width: 100%;
  color: #2C5D6E;
  font-size: 14px;
  font-weight: 500;
}

.payment-subtitle{
  color: #434362;
  font-size: 14px;
  font-weight: bold;
}

.payment-type-section img {
  height: 25px;
}