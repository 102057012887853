.home-attention-section {
  padding: 60px;
  border-radius: 20px;
  background-color: #EDF2FA;
  margin-bottom: 120px;
}

.home-attention-section .title {
  color: #434362;
  font-size: 35px;
  font-weight: bold;
  margin: 20px auto;
  margin-bottom: 24px;
  max-width: 700px;
  text-align: center;
}

.home-attention-section .subtitle {
  color: #434362;
  font-size: 16px;
  line-height: 25px;
  margin: 0px auto 40px auto;
  text-align: center;
}

.home-attention-section .MuiFormHelperText-root {
  background-color: #EDF2FA;
}

.home-attention-section .alerta-purple-button {
  height: 55px;
}

.home-attention-section .sended {
  text-align: center;
  color: #57578E;
  font-size: 15px;
  font-weight: bold;
}

.home-attention-section .sended .icon {
  margin: 10px;
}

.home-attention-section .sended p {
  max-width: 400px;
  margin: auto;
}

@media (max-width: 768px) {
  .home-attention-section {
    padding: 40px;
    margin-left: -30px;
    margin-right: -30px;
    border-radius: 0px;
    margin-bottom: 90px;
  }
  
}