.principal-email-validation-code-container {
  height: 100%;
  width: 100%;
  background-color: #f9f9f9;
  padding: 50px 0px;
}

.email-validation-code-container {
  margin: 40px auto 80px auto;
  text-align: center;
  width: 330px;
}

.email-validation-code-content-arrow {
  margin-top: 13px;
  margin-bottom: -45px;
}

.email-validation-code-arrow-back {
  margin-left: -60%;
}

.email-validation-code-content-title {
  text-align: center;
}

.email-validation-code-content-title p {
  color: #434362;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 25px;
  font-weight: bold;
  line-height: 32px;
}

.email-validation-code-content-text-mark {
  margin: -48px -5px 60px 215px;
}

.email-validation-code-text-mark {
  width: 259px;
  height: 17px;
  background-color: #fdd44b;
  margin-top: -15px;
  z-index: 1;
  margin: 0px auto;
}

.email-validation-code-content-email {
  text-align: center;
  margin: 30px 0px;
}

.email-validation-code-content-email p {
  color: #228397;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 16px;
  font-weight: bold;
  line-height: 22px;
}

.email-validation-code-content-subtitle {
  text-align: center;
  margin: 20px 0px 30px 0px;
}

.email-validation-code-content-subtitle p {
  color: #434362;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: normal;
  line-height: 22px;
}

.email-validation-code-content-subtitle p span {
  color: #434362;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: bold;
  line-height: 20px;
}

.email-validation-code-form-container {
  /* margin: 40px 13%; */
}

.email-validation-code-button-container {
  margin: 40px 0px 0px 0px;
}

.email-validation-code-content-link {
  display: flex;
  justify-content: center;
  margin-top: 25px;
  align-items: center;
  cursor: pointer;
}

.email-validation-code-img {
  margin-right: 10px;
  height: 11px;
}

.email-validation-code-link {
  font-size: 13px;
  color: #383881;
  line-height: 20px;
  font-weight: 500;
  margin-right: 7px;
}

.email-validation-code-button-container {
  margin-top: 40px;
}

.email-validation-code-button {
  background-color: #5A549D !important;
  color: #fff !important;
  width: 250px;
  height: 50px;
}
.email-validation-code-button-progress {
  position: absolute;
  left: 80%;
  top: 10px;
}
/* Ipad */

@media (max-width: 769px) {
  .principal-email-validation-code-container {
    text-align: center;
  }
  .email-validation-code-container {
    background-image: none;
    background-color: #f9f9f9;
  }
  .email-validation-code-content-title {
    text-align: center;
  }
  .email-validation-code-content-title p {
    color: #434362;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    font-size: 25px;
    font-weight: bold;
    line-height: 32px;
  }
  .email-validation-code-content-text-mark {
    margin: -79px 0px 82px 210px;
  }
  .email-validation-code-text-mark {
    width: 90px;
    height: 17px;
    background-color: #fdd44b;
    margin: 0px auto;
  }
  .email-validation-code-content-email {
    text-align: center;
    margin: 20px 0px 30px 0px;
  }
  .email-validation-code-content-email p {
    color: #228397;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    font-size: 16px;
    font-weight: bold;
    line-height: 22px;
  }
  .email-validation-code-content-subtitle {
    text-align: center;
    margin-top: 20px;
    margin-bottom: 30px;
  }
  .email-validation-code-content-subtitle p {
    color: #434362;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
  }
  .email-validation-code-form-container {
    /* margin: 0 20%; */
  }
  .email-validation-code-button-container {
    margin: 60px 10%;
  }
  .email-validation-code-content-link {
    display: flex;
    justify-content: center;
    margin-top: 25px;
    align-items: center;
    cursor: pointer;
  }
  .email-validation-code-img {
    margin-right: 10px;
    height: 11px;
  }
  .email-validation-code-link {
    font-size: 13px;
    color: #383881;
    line-height: 20px;
    font-weight: 500;
    margin-right: 7px;
  }
}

/* Mobile */

/* @media (max-width: 400px) {
  .email-validation-code-button-container {
    margin: 60px 0px 40px 0px;
  }
  .email-validation-code-form-container {
    margin: 0 10%;
  }
} */