.principal-register-car-container {
  height: 100%;
  width: 100%;
  background-color: #f9f9f9;
  padding: 50px 0px;
  margin-bottom: 60px;
}

.register-car-container {
  display: flex;
  justify-content: center;
  margin: 60px 0px;
  /* flex-direction: column; */
}

.register-car-content-button {
  width: 100%;
  text-align: center;
  padding: 15px 0;
  background-color: #f9f9f9;
}

.register-car-content-arrow {
  margin-top: 13px;
  margin-bottom: -45px;
}

.register-car-arrow-back {
  margin-left: -20%;
}

.register-car-content-title {
  text-align: center;
}

.register-car-content-title p {
  color: #434362;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 25px;
  font-weight: bold;
  line-height: 32px;
}

.register-car-content-title p>span {
  background: -webkit-linear-gradient(top, transparent 60%, #fdd44b 40%);
}

.register-car-content-subtitle {
  text-align: center;
}

.register-car-content-subtitle p {
  color: #434362;
  font-family: "Roboto";
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.register-car-content-text-mark {
  margin: -17px 0px 20px 268px;
}

.register-car-text-mark {
  width: 135px;
  height: 17px;
  background-color: #fdd44b;
  margin-top: -15px;
  z-index: 1;
  margin: 0px auto;
}

.register-car-card-container {
  margin-top: 35px;
}

.check-card-payment-container .check-card-payment-button {
  align-self: flex-start;
}

.check-card-payment-container .check-card-payment-label {
  font-weight: 300;
  color: #707C89;
}

.register-car-card.active {
  box-shadow: none;
  background-color: #fff;
  border: solid #17788D;
}

.register-car-card {
  box-shadow: none !important;
  background-color: #f0f1f4;
}

.register-car-card:hover {}

.register-car-close-dialog-container {
  margin: 0 auto;
  max-width: 400px;
}

.register-car-close-dialog-title {
  font-family: "Roboto";
  font-size: 15px;
  font-weight: bold;
  line-height: 20px;
  text-align: center;
}

.register-car-close-dialog-button-acept {
  font-family: "Roboto";
  font-size: 15px;
  font-weight: bold;
  line-height: 20px;
  text-align: center;
}

.register-car-close-dialog-button-cancel {
  font-family: "Roboto";
  font-size: 15px;
  font-weight: bold;
  line-height: 20px;
  text-align: center;
}

.register-car-card-content-active {
  height: 80px;
  padding: 0 !important;
  margin: 0;
  cursor: pointer;
  background-color: white;
  border: solid #17788D;
  border-radius: 4px;
}

.register-label {
  background: #f9f9f9;
  text-align: right;
}

.register-label img {
  height: 10px;
  width: 12px;
}

.register-label span {
  font-family: "Roboto";
  font-size: 10px;
  font-weight: 400;
  line-height: 13px;
  color: #17788D;
  margin-left: 5px;
}

.register-car-card-content-inactive {
  background-color: #F0F1F4;
  padding: 0 0 5px 0 !important;
  margin: 0;
  border-radius: 4px;
  border: solid #F0F1F4;
  cursor: pointer;
}

.register-car-card-content-add {
  background-color: #F9F9F9;
  padding: 0 0 5px 0 !important;
  margin: 0;
  border-radius: 4px;
  cursor: pointer;
  color: #17788D;
}

.register-car-card-content-add svg{
  opacity: .3;
}


.register-car-card-content-add .register-car-card-title{
  color: #17788D;
  font-size: 11px;
}

.register-car-card-title-images {
  text-align: center;
}

.register-car-card-title-images .card-img-car {
  margin-left: 60px;
  padding-top: 10px;
  height: 18px;
  width: 31px;
}

.register-car-card-title-images .card-img-icon {
  margin-left: 40px;
  padding-bottom: 7px;
  display: inline;
}

.register-car-card-title {
  font-family: "Roboto";
  text-align: center;
  font-size: 14px;
  line-height: 19px;
  font-weight: 500;
  color: #434362;
  margin: 0;
}

.register-car-card-text {
  font-family: "Roboto";
  text-align: center;
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  color: #707c89;
  margin: 0;
}

.register-car-button-container {
  margin: 50px 30px 0px 30px;
}

.register-car-button {
  width: 250px;
  height: 50px;
}

.register-car-button-disabled {
  width: 250px;
  height: 50px;
}

.register-car-button-progress {
  position: absolute;
  left: 70%;
  top: 10px;
}

/* mobile */

.principal-register-car-container {
  height: 100%;
  width: 100%;
  background-color: #f9f9f9;
  padding: 50px 0px;
}

.register-car-content-title {
  text-align: center;
}

.register-car-content-title p {
  color: #434362;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 25px;
  font-weight: bold;
  line-height: 32px;
}

.register-car-content-subtitle {
  text-align: center;
}

.register-car-content-subtitle p {
  color: #434362;
  font-family: "Roboto";
  font-size: 14px;
  font-weight: normal;
  line-height: 20px;
}

.register-car-content-text-mark {
  margin: -15px 0px 15px 0px;
}

.register-car-text-mark {
  width: 135px;
  height: 15px;
  background-color: #fdd44b;
  margin-top: -15px;
  z-index: 1;
  margin: 0px auto;
}

.register-car-section-cards {
  /* width: 100%;
  padding-top: 25px;
  min-height: 200px; */
  width: 100%;
  display: flex;
  overflow: auto;
  padding: 0 20px;
  max-width: 700px;
}

.register-car-card-container {
  /* width: 30%;
  margin-bottom: 15px;
  float: left;
  margin-right: 15px; */
}

.register-car-card-container:first-child {
  /* width: 30%;
  margin-bottom: 15px;
  float: left;
  margin-right: 15px; */
}

.register-car-card.active {
  box-shadow: none;
  background-color: #fff;
  border: solid #17788D;
}

.register-car-card {
  box-shadow: none;
  background-color: #f0f1f4;
}

.register-label {
  background: #f9f9f9;
  text-align: right;
}

.register-label img {
  height: 10px;
  width: 12px;
}

.register-label span {
  font-family: "Roboto";
  font-size: 10px;
  font-weight: 400;
  line-height: 13px;
  color: #17788D;
  margin-left: 5px;
}

.register-car-card-title-images {
  text-align: center;
}

.register-car-card-title-images .card-img-car {
  margin-left: 55px;
  padding-top: 10px;
  height: 18px;
  width: 31px;
}

.register-car-card-title-images .card-img-icon {
  margin-left: 35px;
  padding-bottom: 7px;
  display: inline;
}

.register-car-card-title {
  font-family: "Roboto";
  text-align: center;
  font-size: 14px;
  line-height: 19px;
  font-weight: 500;
  color: #434362;
}

.register-car-card-text {
  font-family: "Roboto";
  text-align: center;
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  color: #707c89;
}

.register-car-button-container {
  text-align: center;
}

.register-car-button-container button {
  width: 250px;
}

.register-car-button {
  width: 250px;
  height: 50px;
}

.register-car-button-disabled {
  width: 250px;
  height: 50px;
}

.register-car-button-progress {
  position: absolute;
  left: 70%;
  top: 10px;
}

.register-car-detail-input-label {
  margin: 0 !important;
  width: 100%;
}

.register-car-detail-input-label .MuiFormControlLabel-label {
  font-family: "Roboto";
  font-size: 11px;
  font-weight: 400;
  line-height: 15px;
  color: #707c89;
  margin: 5px 0px 15px 0px;
  align-self: flex-start;
}

@media (max-width: 769px) {
  .register-car-container {
    margin-top: 0;
    margin-bottom: 90px;
  }
  .register-car-content-button {
    position: fixed;
    left: 0;
    bottom: 0;
    text-align: center;
    background-color: #f9f9f9;
    padding: 15px 0;
  }

  .register-car-content-button button {
    width: -webkit-fill-available;
    margin: 0 20px;
  }

  
}