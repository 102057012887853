.service-main-section{
    height: 100%;
}

.service-main-section .service-overflow {
    overflow-y: auto;
    overflow-x: hidden;
    height: 0px;
}

.service-main-section .service-main-title {
    background-color: #EEEEF5;
    border-radius: 5px;
    color: #6C6C6C;
    font-size: 14px;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
}

.service-main-section .service-main-title.sticky-header{
    position: sticky;
    top: 0;
}

.service-main-section .service-main-title span {
    padding: 10px 20px;
}

.service-main-section .service-main-title button {
    background-color: #E0DFE6;
    color: #434362;
    border: none;
    font-size: 10px;
    font-weight: bold;
    border-radius: 5px;
    cursor: pointer;
    margin: 5px;
    padding: 7px 20px;
}

.service-main-section .service-main-title button.update {
    background-color: #5A549D;
    color: #fff;
}

.service-main-section .service-main-title button.back {
    background-color: transparent;
    color: #17788D;
    padding: 7px 10px;
}

.service-main-section .service-main-title button.update:disabled {
    background-color: #E0DFE6;
    color: #fff;
    cursor: auto;
}

.service-main-section .service-main-content {
    padding: 20px 30px;
}

.service-main-section .content-title {
    background-color: #F5F5F5;
    border-radius: 5px;
    color: #6C6C6C;
    font-size: 14px;
    padding: 10px 15px;
    font-weight: bold;
    margin: 0px -30px;
    margin-bottom: 20px;
}

.service-main-section .content-section {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.service-main-section .main-label {
    color: #9A9A9A;
    font-size: 12px;
    margin-right: 5px;
    font-weight: 400;
}

.service-main-section .main-sublabel {
    font-weight: bold;
    line-height: 20px;
}

.service-main-section .main-variable {
    color: #17788D;
    font-size: 12px;
    font-weight: bold;
    text-align: right;
}

.service-main-section .main-variable.alert {
    color: #D60013;
}

.service-main-section .main-variable span {
    margin-bottom: 5px;
}

.service-main-section .main-subvariable {
    display: block;
    font-size: 10px;
    font-weight: 400;
}

.service-main-section .main-plate {
    background-color: #F259AF;
    font-size: 12px;
    font-weight: bold;
    color: #fff;
    padding: 5px;
    border-radius: 5px;
}

.service-main-section .main-button {
    color: #fff;
    background-color: #5A549D;
    border: none;
    padding: 10px 30px;
    font-size: 13px;
    border-radius: 5px;
    cursor: pointer;
}

.success-content-section {
    margin-top: 20px;
    margin-bottom: 30px;
    background-color: #EFF5F6;
    padding: 15px 20px;
    border-radius: 5px;
}

.success-content-section .success-title {
    color: #17788D;
    font-weight: 450;
    font-size: 14px;
    line-height: 1.5;
    display: flex;
    justify-content: space-between;
}

.success-content-section .success-title span{
    margin-right: 10px;
}

.alert-content-section {
    margin-bottom: 30px;
    background-color: #FDF5F7;
    padding: 15px 30px 5px 30px;
    border-radius: 5px;
}

.alert-content-section .alert-title {
    color: #C1536F;
    font-weight: bold;
    font-size: 12px;
    margin-bottom: 10px;
}

.info-content-section {
    margin-bottom: 30px;
    background-color: #F8F8F8;
    padding: 20px 30px 10px 30px;
    border-radius: 5px;
}

.info-content-section .info-title {
    color: #646B72;
    font-weight: bold;
    font-size: 14px;
    margin-bottom: 10px;
}

.info-content-section .info-button {
    color: #5A549D;
    background-color: #fff;
    border: solid 1px #5A549D;
    padding: 10px 30px;
    font-size: 13px;
    border-radius: 5px;
    cursor: pointer;
}

.service-main-footer {
    padding: 20px 30px;
}

.service-main-footer .footer-title, .service-main-footer p strong {
    font-size: 14px;
    font-weight: bold;
    color: #646B72;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
}

.service-main-footer .footer-content, .service-main-footer p {
    font-size: 12px;
    color: #646B72;
    line-height: 20px;
    font-weight: 300;
    margin-bottom: 20px;
}

.service-main-footer .footer-content, .service-main-footer ul {
    font-size: 12px;
    color: #646B72;
    line-height: 20px;
    font-weight: 300;
    margin-bottom: 20px;
}

.service-main-footer .footer-image img {
    text-align: center;
    width: 100%;
}

@media (max-width: 769px) {
    .service-main-footer {
        padding: 20px 0px;
    }
    .service-main-section .service-overflow {
        height: 600px !important;
    }

}