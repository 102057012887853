.estate-section {
  border-radius: 5px;
  /* height: 230px; */
  padding-bottom: 3px;
}

.estate-section.active {
  background-color: #FFF;
  box-shadow: 0 0 0 3px #17788D;
}

.estate-section.inactive {
  background-color: #f0f1f4;
}

.estate-section-container {
  cursor: pointer;
}

.estate-section-container-card {
  border-radius: 5px;
  height: 130px;
  text-align: center;
  position: relative;
}

.estate-section-container-card.active {
  background-color: #fff;
}

.estate-section-container-card.inactive {
  background-color: #f0f1f4;
}

.estate-section-container-card.inactive .estate-section-arrow-done {
  position: absolute;
  top: 10px;
  right: 10px;
}

.estate-section-container-card.inactive .estate-section-text {
  font-family: "Roboto";
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  color: #383881;
  padding-top: 10px;
}

.estate-section-arrow-done {
  position: absolute;
  top: 10px;
  right: 10px;
}

.estate-section-arrows_container {
  display: grid;
}

.estate-section-arrows_container img {
  cursor: pointer;
}

.estate-section-text {
  font-family: "Roboto";
  font-weight: bold;
  font-size: 12px;
  line-height: 12px;
  color: #383881;
  padding-top: 10px;
}

.estate-section-img {
  margin: 10px 10px 0px 10px;
  max-height: 70px;
}

.estate-section.inactive .estate-section-img-icon {
  color: #fff;
}
.estate-section.active .estate-section-img-icon{
  color: #ececec;
}

.estate-section-counter-container {
  display: flex;
  align-items: center;
  margin: 10px;
  border-radius: 5px;
  height: 38px;
  justify-content: space-around;
}

.estate-section-counter-container.active {
  background-color: #f0f1f4;
}

.estate-section-counter-container.inactive {
  border: none;
  background-color: #fcfcfc;
  border-radius: 5px;
  opacity: 1;
}

.estate-section-counter-img {
  margin: 2px 30% 2px 10%;
  width: 33px;
  height: 16px;
  opacity: 1;
}

.estate-section-counter-img.active {
  opacity: 1;
}

.estate-section-counter-img.inactive {
  opacity: 0.4;
}

.estate-section-arrows_container img {
  margin: 2px;
}

.estate-section-arrows_container img.active {
  opacity: 1;
}

.estate-section-arrows_container img.inactive {
  opacity: 0.4;
}

.estate-section-counter-buttons {
  cursor: pointer;
  margin: 0px 3px;
}

.estate-section-counter-input {
  width: 20px;
  margin: 0px 3px;
  border: none;
  font-weight: bold;
  font-size: 16px;
  background-color: transparent;
}

.estate-section-counter-input:focus {
  outline: none;
}
.estate-section-counter-input.active {
  color: #383881;
}

.estate-section-counter-input.inactive {
  color: #c0bfda;
}

@media (max-width: 768px) {
  .estate-section-counter-input {
    text-align: center;
    width: 20px;
    padding: 3px;
    border: solid 1px;
    background-color: #fff;
    border-radius: 5px;
  }
  .estate-section-counter-input.inactive {
    /* margin: 0px 3px; */
    border-color: #c0bfda;
  }
  .estate-section-counter-input.active {
    border-color: #383881;
  }

}