.vehicle-data-content {
  background-color: #F8F8F8;
  padding: 20px;
  font-size: 13px;
  font-weight: bold;
  font-family: "Roboto";
  line-height: 15px;
  color: #434362;
}

.vehicle-data-content.inactive {
  color: #AFAFAF;
  margin-bottom: 10px;
}
.vehicle-data-content .car-label{
  font-size: 18px; 
  font-weight: bold;
  line-height: 24px; 
  color: #434362;
}
.vehicle-data-content.inactive .car-label{
  color: #AFAFAF
}

.vehicle-data-img {
  padding: 10px 40px 10px 40px;
}

.vehicle-data-img img {
  width: 70px;
  height: 28px;
}

.my-alerts-container-card-info {
  display: flex;
  padding: 10px 40px 40px 40px;
  justify-content: space-between;
  align-items: center;
}

.vehicle-data-folio-container {
  display: flex;
  padding: 0px 40px;
  justify-content: space-between;
  align-items: center;
}

.vehicle-data-content .folio {
  font-size: 16px;
  font-weight: bold;
  font-family: "Roboto";
  line-height: 21px;
  margin-top: 5px;
  color: #228397;
}
.vehicle-data-content.inactive .folio{
  color: #AFAFAF
}

.vehicle-data-content .location-container {
  display: flex;
  width: 143px;
}

.vehicle-data-content .location-container .image-vehicle-type {
  margin: 0 10px;
}
.vehicle-data-content .location-container {
  display: flex;
  width: 143px;
}

.vehicle-data-content .location-container p {
  color: #707c89;
  font-size: 13px;
  font-weight: 400;
  font-family: "Roboto";
  line-height: 18px;
  padding: 20px 0 20px 0;
  white-space: nowrap;
}
.vehicle-data-content.inactive .location-container p{
  color: #AFAFAF
}

.vehicle-data-content .location-container img {
  margin-right: 5px;
}

.vehicle-data-content .title {
  font-size: 10px;
  font-weight: 400;
  font-family: "Roboto";
  line-height: 15px;
  color: #707c89;
}

.vehicle-data-content .content {
  margin-bottom: 20px;
}
.vehicle-data-content .content:last-child {
  margin-bottom: 0px;
}

.vehicle-data-content.inactive .title{
  color: #AFAFAF
}

.vehicle-data-content .subscription-canceled-container {
  color: #C9255C;
  line-height: 20px;
}

.vehicle-data-content .subscription-canceled-container .title {
  color: #C9255C !important;
  font-weight: bold;
  font-size: 10px;
}

.vehicle-data-content .subscription-canceled-container .subtitle {
  font-size: 12px;
  font-weight: 400;
}

.vehicle-data-content .subscription-canceled-container .date {
  font-size: 12px;
  font-weight: bold;
}

.vehicle-data-content .action-buttons {
  font-size: 10px !important;
  font-family: "Roboto" !important;
  font-weight: bold !important;
  line-height: 12px !important;
  color: #434362 !important;
  background: #e0dfe6 !important;
  text-transform: none !important;
  margin-right: 10px !important;
  min-width: 50px !important;
  justify-content: end !important;
  height: 18px !important;
  padding: 10px !important;
}

.vehicle-data-content .print {
  background-color: #fff !important;
  border: solid #2c5d6e 2px !important;
  padding: 5px !important;
  max-width: 185px;
}

.vehicle-data-content .print img {
  margin-right: 10px;
}

.vehicle-data-content .print span {
  color: #2c5d6e;
  font-size: 10px;
  font-family: "Roboto";
  line-height: 12px;
  text-transform: none;
  font-weight: bold;
}
.vehicle-data-content .logo-container {
  margin: -10px;
  margin-right: 0px;
  background-color: #EEEEF5;
}

.vehicle-data-content .brand-container, 
.vehicle-data-content .user-data-container, 
.vehicle-data-content .payment-data-container{
  width: 100%;
  margin: 0px 15px;
}

/* .vehicle-data-content {
  font-size: 13px;
  font-weight: bold;
  font-family: "Roboto";
  line-height: 15px;
  color: #434362;
} */

.vehicle-data-divider {
  display: flex;
}

@media (max-width: 769px) {
  .vehicle-data-content .logo-container{
    margin: 0;
    margin-bottom: 20px;
  }
  .vehicle-data-img {
    padding: 10px 20px 10px 20px;
  }
  .vehicle-data-img img {
    width: 70px;
    height: 28px;
  }
  .vehicle-data-folio-container {
    display: flex;
    padding: 0px 20px;
    justify-content: space-between;
    align-items: center;
  }
  .vehicle-data-folio {
    font-size: 16px;
    font-weight: bold;
    font-family: "Roboto";
    line-height: 21px;
    color: #383881;
    margin: 0;
  }
  .vehicle-data-location-container {
    display: flex;
    width: 143px;
  }
  .vehicle-data-location-container p {
    color: #707c89;
    font-size: 13px;
    font-weight: 400;
    font-family: "Roboto";
    line-height: 18px;
  }
  .vehicle-data-location-container img {
    margin-right: 5px;
  }
  .my-alerts-container-card-info {
    display: flex;
    padding: 10px 20px 40px 20px;
    justify-content: space-between;
    align-items: center;
  }
  .vehicle-data-title {
    font-size: 10px;
    font-weight: 400;
    font-family: "Roboto";
    line-height: 15px;
    color: #707c89;
  }
  .vehicle-data-content {
    font-size: 13px;
    font-weight: bold;
    font-family: "Roboto";
    line-height: 15px;
    color: #434362;
    padding: 10px;
  }
  .vehicle-data-divider {
    display: flex;
    margin-top: -50px;
  }
  .vehicle-data-content .print{
    max-width: none;
  }
}