.plate-used-single-report-dialog-content {
    padding: 20px 60px;
    text-align: center;
    font-family: 'Roboto';
}

.plate-used-single-report-dialog-title {
    font-weight: bold;
    font-size: 15px;
    max-width: 265px;
    display: inline-block;
}

.plate-used-single-report-dialog-subtitle {
    font-weight: 400;
    max-width: 265px;
    line-height: 20px;
    font-size: 13px;
    color: #434362;
    display: inline-block;
}

.plate-used-single-report-dialog-button-container {
    padding-top: 40px;
}

@media (max-width: 768px) {
    .plate-used-single-report-dialog-content {
        padding: 30px;
    }
}