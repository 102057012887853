.principal-estate-data-recived-container {
  height: 100%;
  width: 100%;
  background-color: #f9f9f9;
  padding: 50px 0px;
}

.estate-data-recived-container {
  min-height: 730px;
  max-width: 520px;
  margin: 0 auto;
}

.estate-data-recived-content-arrow {
  margin-top: 13px;
  margin-bottom: -45px;
}

.estate-data-recived-arrow-back {
  margin-left: -60%;
}

.estate-data-recived-content-title {
  text-align: center;
}

.estate-data-recived-content-title p {
  color: #434362;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 25px;
  font-weight: bold;
  line-height: 32px;
}

.estate-data-recived-content-title p>span {
  background: -webkit-linear-gradient(top, transparent 60%, #fdd44b 40%);
}

.estate-data-recived-content-text-mark {
  margin: -47px -5px 50px 0px;
}

.estate-data-recived-text-mark {
  width: 125px;
  height: 17px;
  background-color: #fdd44b;
  margin-top: -15px;
  z-index: 1;
  margin: 0px auto;
}

.estate-data-recived-content-subtitle {
  text-align: center;
  margin: 20px 0px 30px 0px;
}

.estate-data-recived-content-subtitle p {
  color: #434362;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: normal;
  line-height: 22px;
}

.estate-data-recived-content-subtitle p span {
  color: #434362;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: bold;
  line-height: 20px;
}

.estate-data-recived-form-container {
  margin: 60px 13%;
}

.estate-data-recived-content-button {
  text-align: center;
  position: relative;
  margin-top: 40px;
}

.estate-data-recived-button-progress {
  position: absolute;
  left: 80%;
  top: 8px;
}

.estate-data-recived-content-link {
  display: flex;
  justify-content: center;
  margin-top: 25px;
  align-items: center;
}

.estate-data-recived-img {
  margin-right: 10px;
  height: 14px;
}

.estate-data-recived-link {
  font-size: 13px;
  color: #5c5ca5;
  line-height: 20px;
  font-weight: 500;
  margin-right: 7px;
  padding-top: 2px;
}

/* Ipad */

@media (max-width: 769px) {
  .principal-estate-data-recived-container {
    text-align: center;
  }
  .estate-data-recived-container {
    background-image: none;
    background-color: #f9f9f9;
    height: auto;
    min-height: 650px;
    padding-bottom: 50px;
  }
  .estate-data-recived-content-title {
    text-align: center;
  }
  .estate-data-recived-content-title p {
    color: #434362;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    font-size: 25px;
    font-weight: bold;
    line-height: 32px;
  }
  .estate-data-recived-content-text-mark {
    margin: -81px 0px 90px 0px;
  }
  .estate-data-recived-text-mark {
    width: 150px;
    height: 17px;
    background-color: #fdd44b;
    margin: 0px auto;
  }
  .estate-data-recived-content-subtitle {
    text-align: center;
    margin-top: 20px;
    margin-bottom: 30px;
  }
  .estate-data-recived-content-subtitle p {
    color: #434362;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
  }
  .estate-data-recived-form-container {
    margin: 0 20%;
  }
  .estate-data-recived-content-button {
    text-align: center;
    position: relative;
  }
  .estate-data-recived-button-progress {
    position: absolute;
    left: 80%;
    top: 8px;
  }
  .estate-data-recived-button-container {
    margin: 60px 10%;
  }
  .estate-data-recived-content-link {
    display: flex;
    justify-content: center;
    margin-top: 25px;
    align-items: center;
  }
  .estate-data-recived-img {
    margin-right: 10px;
    height: 14px;
  }
  .estate-data-recived-link {
    font-size: 13px;
    color: #5c5ca5;
    line-height: 20px;
    font-weight: 500;
    margin-right: 7px;
  }
}

/* Mobile */

@media (max-width: 400px) {
  .estate-data-recived-button-container {
    margin: 60px 0px 40px 0px;
  }
  .estate-data-recived-form-container {
    margin: 0 10%;
  }
}