.basic-single-report-page .container{
  max-width: 800px !important;
  margin-bottom: 60px !important;
}

@media (max-width: 769px) {
  .basic-single-report-page .container {
    margin: 0 20px;
  }
}
