.wrap-card-step-molecules-container {
  width: 30%;
}

.wrap-card-step-molecules {
  /* display: flex;
  justify-content: center;
  align-items: center; */
  background-color: rgba(256, 256, 256, 1);
  border-radius: 5px;
  /* filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161)); */
  width: 32%;
  /* height: 490px; */
  box-shadow: 0px 0px 10px 2px #e7e7e794;
}

.wrap-card-step-molecules>.content-card-step-molecules {
  padding: 40px 0;
  /* width: 74%;
  height: 85%;
  justify-content: center;
  align-items: center; */
}

.wrap-card-step-molecules>.content-card-step-molecules>.number-card-step {
  font-family: "Stardos Stencil", cursive;
  font-style: normal;
  font-weight: bold;
  font-size: 45px;
  color: rgba(217, 26, 86, 1);
  text-align: center;
  margin-bottom: 13px;
}

.wrap-card-step-molecules>.content-card-step-molecules>.title-card-step {
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  color: rgba(67, 67, 98, 1);
  text-align: center;
  line-height: 25px;
  margin: 20px 0;
}

.wrap-card-step-molecules>.content-card-step-molecules>.message-card-step {
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  color: #707c89;
  line-height: 21px;
  text-align: center;
  margin: auto;
  margin-bottom: 20px;
  max-width: 215px;
}

.wrap-card-step-molecules>.content-card-step-molecules>.wrap-steps-card-step {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.wrap-card-step-molecules .wrap-steps-card-step .wrap-input-img-card-step {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.wrap-card-step-molecules .wrap-steps-card-step .wrap-input-img-card-step .content-input-card-step {
  width: 85%;
}

.wrap-card-step-molecules .wrap-steps-card-step .wrap-input-img-card-step .img-question {
  width: 25px;
  height: auto;
  padding-top: 21px;
}

.wrap-card-step-molecules .wrap-steps-card-step .plus-message {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  color: #17788d;
  margin-top: 26px;
}

.wrap-card-step-molecules .wrap-steps-card-step .payment-box {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;
  width: 100%;
  background-color: rgba(237, 237, 237, 1);
  filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
  margin-bottom: 20px;
  border-radius: 5px;
}

.wrap-card-step-molecules .wrap-steps-card-step .payment-box .content-payment-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 89%;
}

.wrap-card-step-molecules .wrap-steps-card-step .payment-box .content-payment-box img {
  width: 80px;
  height: auto;
}

.wrap-card-step-molecules .wrap-steps-card-step .payment-box .content-payment-box p {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: rgba(82, 82, 82, 1);
}

/* circle */

.wrap-card-step-molecules-circle {
  border-radius: 100%;
  background: #EDF2FA;
}

.content-card-step-molecules-circle {
  width: 250px;
  height: 250px;
}

.img-card-step-circle {
  height: 75px;
  width: 60px;
  margin-top: 18%;
}

.title-card-step-circle {
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  color: rgba(67, 67, 98, 1);
  text-align: center;
  line-height: 25px;
  max-width: 175px;
  margin: auto;
}

.wrap-card-step-molecules>.content-card-step-molecules>.img-card-step {
  min-height: 57px;
}

@media (min-width: 1025px) {
  .wrap-card-step-molecules {
    width: 30%;
  }
}

@media (max-width: 1025px) {
  .wrap-card-step-molecules {
    /* margin-left: 5px; */
    margin-bottom: 40px;
    /* width: 100%; */
    /* height: 310px; */
    font-size: 20px;
  }

  .wrap-card-step-molecules>.content-card-step-molecules {
    /* height: 94%; */
    padding: 30px 0 25px;
  }

  .wrap-card-step-molecules>.content-card-step-molecules>.img-card-step {
    margin: 0 auto;
    padding: 0px 0px 15px;
    min-height: 57px;
  }

  .wrap-card-step-molecules>.content-card-step-molecules>.title-card-step {
    font-size: 17px;
    font-weight: bolder;
    line-height: 23px;
    margin: 0px 0 18px;
  }

  .wrap-card-step-molecules>.content-card-step-molecules>.message-card-step {
    font-weight: normal;
    font-size: 13px;
    line-height: 21px;
    color: #707c89;
    margin-bottom: 10px;
  }

  .wrap-card-step-molecules .wrap-steps-card-step .payment-box .content-payment-box p {
    font-size: 10px;
  }
}

@media (min-width: 500px) and (max-width: 768px) {
  .wrap-card-step-molecules {
    width: 70% !important;
    margin: 0px auto 40px;
  }

}

@media (max-width: 768px) {
  .wrap-card-step-molecules-container {
    width: 100%;
  }

  .wrap-card-step-molecules>.content-card-step-molecules>.message-card-step {
    /* padding: 0 30px; */
  }

  .wrap-card-step-molecules {
    /* margin-left: 5px; */
    margin-bottom: 40px;
    width: 100%;
    /* height: 310px; */
    font-size: 20px;
  }

  .wrap-card-step-molecules-circle {
    width: 256px;
    border-radius: 100%;
    height: 256px;
    margin: 0 auto;
    margin-bottom: 20px;
  }

  .content-card-step-molecules-circle {
    /* margin: 45px 0px; */
    padding-top: 18%;
  }

  .img-card-step-circle {
    margin-top: 0%;
    width: 85px;
  }

  .title-card-step-circle {
    margin: 10px auto;
  }

  .wrap-card-step-molecules>.content-card-step-molecules {
    /* height: 94%; */
    padding: 26px 0;
  }

  .wrap-card-step-molecules>.content-card-step-molecules>.img-card-step {
    margin: 0 auto;
    padding: 5px 0px;
    min-height: 57px;
  }

  .wrap-card-step-molecules>.content-card-step-molecules>.title-card-step {
    font-size: 20px;
    font-weight: bold;
    line-height: 26px;
    margin: 0px 0 20px;
  }

  .wrap-card-step-molecules>.content-card-step-molecules>.message-card-step {
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    color: #707c89;
    max-width: 267px !important;
  }

  .wrap-card-step-molecules .wrap-steps-card-step .payment-box .content-payment-box p {
    font-size: 10px;
  }
}