.home-opinion-section {
  margin-bottom: 120px;
}

.home-opinion-section .title {
  color: #434362;
  font-size: 35px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 24px;
}

.home-opinion-section .subtitle {
  color: #434362;
  font-size: 16px;
  max-width: 500px;
  line-height: 25px;
  margin: 0px auto 70px auto;
  text-align: center;
}

.home-opinion-section .opinion-panel {
  min-height: 267px;
}

.home-opinion-section .opinion-card {
  background-color: #FFFFFF;
  padding: 50px 30px;
  border-radius: 10px;
}

.home-opinion-section .opinion-card .description {
  line-height: 25px;
  margin-bottom: 20px;
}

.home-opinion-section .opinion-card .name {
  color: #1A93B1;
  font-weight: bold;
  font-size: 18px;
  width: 100%;
}

.home-opinion-section .opinion-card .occupation {
  color: #5C5C5C;
  font-size: 14px;
  line-height: 25px;
}

.home-opinion-section .opinion-tab-control {
  border-bottom: 5px solid #D2E4E8;
  margin: 0px 5px;
  min-width: 60px;
}

.home-opinion-section .opinion-tabs-control .MuiTabs-indicator {
  background-color: #9DBCC4;
  height: 5px;
}

.home-opinion-section .quotes-start {
  position: absolute;
  margin-top: -40px;
}

.home-opinion-section .quotes-end {
  position: absolute;
  margin-top: 75px;
  align-self: flex-end;
}

@media (max-width: 768px) {
  .home-opinion-section .quotes-end {
    position: absolute;
    margin-top: 600px;
    align-self: flex-end;
  }
  .home-opinion-section {
    margin-bottom: 90px;
  }
}