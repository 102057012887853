.theft-report-article {
    margin: 40px 0;
}

.theft-report .service-main-footer {
    padding: 10px 20px;
    margin-bottom: 40px;
}

.theft-report .success-content-section,
.theft-report .footer-content,
.theft-report .service-main-title {
    margin: 0;
    margin-top: 10px;
}

.theft-report .alert-content-section{
    padding: 0;
    background: none;
    margin-bottom: 0px;
}

.theft-report .alert-content-section .alert-title{
    font-weight: 300;
}
