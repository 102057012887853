.principal-suscriber-container {
  height: 100%;
  width: 100%;
  background-color: #f9f9f9;
  padding: 50px 0px;
}

.suscriber-content-arrow {
  margin-top: 18px;
  margin-bottom: -50px;
}

.suscriber-arrow-back {
  margin-left: 10%;
}

.suscriber-content-title {
  text-align: center;
}

.suscriber-content-title p {
  color: #434362;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 25px;
  font-weight: bold;
  line-height: 32px;
}

.suscriber-content-title p>span {
  background: -webkit-linear-gradient(top, transparent 60%, #fdd44b 40%);
}

.suscriber-content-text-mark {
  margin: -15px 0px 0px 325px;
}

.suscriber-text-mark {
  width: 200px;
  height: 17px;
  background-color: #fdd44b;
  margin-top: -15px;
  z-index: 1;
  margin: 0px auto;
}

.suscriber-form-container {
  margin: 40px auto 80px auto;
  text-align: center;
  width: 330px;
}

.suscriber-content-input {
  margin: 20px 0px;
  position: relative;
}

.suscriber-content-input .MuiFormHelperText-root {
  margin: 0;
  background-color: #f9f9f9;
  padding-top: 5px;
  text-align: right;
}

.suscriber-content-input .MuiInputLabel-outlined {
  font-size: 15px;
}

.suscriber-label-error {
  font-size: 11px;
  color: #ca0f4a;
  position: absolute;
  top: 60px;
  right: 0%;
}

.suscriber-label-error img {
  margin-right: 5px;
}

.suscriber-content-input .inputName .MuiFormHelperText-root {
  color: "#CA0F4A";
  text-align: "end";
}

.suscriber-button-container {
  margin-top: 40px;
}

.suscriber-find-alerts-container {
  text-align: left;
  max-width: 140px;
  position: absolute;
  top: -20px;
  right: -170px;
}

.suscriber-find-alerts-p {
  color: #2c5d6e;
}

.suscriber-find-alerts-p-title {
  font-size: 12px;
  font-weight: bold;
  line-height: 16px;
  margin-bottom: 5px;
  color: #2c5d6e;
}

.suscriber-find-alerts-p-subtitle {
  font-size: 10px;
  font-weight: 400;
  line-height: 15px;
  color: #2c5d6e;
  margin-bottom: 15px;
}

.suscriber-find-alerts-button {
  padding: 7px 14px;
  background-color: #b9d1db;
  border: solid #b9d1db;
  border-radius: 7px;
  font-size: 12px;
  font-weight: bold;
  line-height: 15px;
  color: #2c5d6e;
  cursor: pointer;
}

.div-test {
  width: 100%;
  border: solid;
  height: 40px;
  padding: 0px 50px;
}

/* Ipad */

@media (max-width: 769px) {
  .suscriber-find-alerts-container {
    text-align: left;
    max-width: none;
    position: initial;
    margin-top: 15px;
    background-color: #E9F0F2;
    padding: 15px;
    border-radius: 5px;
  }

  .suscriber-find-alerts-container .suscriber-find-alerts-container-button {
    text-align: center;
  }

  .principal-suscriber-container {
    text-align: center;
  }

  .suscriber-container {
    background-image: none;
    background-color: #f9f9f9;
    height: auto;
    min-width: 300px;
  }

  .suscriber-content-title {
    text-align: center;
  }

  .suscriber-content-title p {
    color: #434362;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    font-size: 25px;
    font-weight: bold;
    line-height: 32px;
  }

  .suscriber-content-text-mark {
    margin: -47px 35px 60px 0px;
  }

  .suscriber-text-mark {
    width: 270px;
    height: 17px;
    background-color: #fdd44b;
    margin: 0px auto;
  }

  .suscriber-content-input {
    margin: 20px 0px;
  }

  .suscriber-content-input .MuiInputLabel-outlined {
    font-size: 15px;
  }

  .suscriber-button-container {
    text-align: center;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    margin: 0;
    padding: 15px 0;
    background-color: #f9f9f9;
  }

  .suscriber-button-container {
    margin: 0 20px;
    width: -webkit-fill-available;
  }

  .suscriber-find-alerts-p {
    color: #2c5d6e;
  }

  .suscriber-find-alerts-p-title {
    font-size: 12px;
    font-weight: bold;
    line-height: 16px;
    margin-bottom: 5px;
    color: #2c5d6e;
  }

  .suscriber-find-alerts-p-subtitle {
    font-size: 10px;
    font-weight: 400;
    line-height: 15px;
    color: #2c5d6e;
    margin-bottom: 15px;
  }

  .suscriber-find-alerts-button {
    padding: 5px 20px;
    background-color: #b9d1db;
    border: solid #b9d1db;
    border-radius: 7px;
    font-size: 10px;
    font-weight: bold;
    line-height: 15px;
    color: #2c5d6e;
    cursor: pointer;
  }

  .suscriber-content-input {
    margin: 20px 0px;
    position: relative;
  }

  .suscriber-label-error {
    font-size: 11px;
    color: #ca0f4a;
    position: absolute;
    top: 60px;
    right: 0%;
  }

  .suscriber-label-error img {
    margin-right: 5px;
  }

  .suscriber-button {
    color: #fff;
    width: 250px;
    height: 50px;
  }

  .suscriber-button-disabled {
    width: 250px;
    height: 50px;
  }

  .suscriber-button-progress {
    position: absolute;
    left: 80%;
    top: 10px;
  }
}