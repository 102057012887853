.principal-not-found-estate-container {
  height: 100%;
  width: 100%;
  background-color: #f9f9f9;
  padding: 50px 0px;
}

.not-found-estate-container {
  max-width: 561px;
  margin: 60px auto;
}

.not-found-estate-content-arrow {
  margin-top: 14px;
  margin-bottom: -45px;
}

.not-found-estate-arrow-back {
  margin-left: -30%;
}

.not-found-estate-content-title {
  text-align: center;
}

.not-found-estate-input-container {
  margin: 20px 0px;
}

.not-found-estate-input-container .MuiInputLabel-outlined {
  font-size: 15px;
}

.not_found_estate-input-container {
  font-size: 20px;
}

.not-found-estate-content-title p {
  color: #434362;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 25px;
  font-weight: bold;
  line-height: 32px;
}

.not-found-estate-content-title p>span {
  background: -webkit-linear-gradient(top, transparent 60%, #fdd44b 40%);
}

.not-found-estate-content-text-mark {
  margin: -14px -57px 23px 100px;
}

.not-found-estate-text-mark {
  width: 180px;
  height: 17px;
  background-color: #fdd44b;
  margin-top: -15px;
  z-index: 1;
  margin: 0px auto;
}

.not-found-estate-content-subtitle {
  text-align: center;
  margin: 20px 0px 20px 0px;
}

.not-found-estate-content-subtitle p {
  color: #434362;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: normal;
  line-height: 22px;
}

.not-found-estate-content-subtitle p span {
  color: #434362;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: bold;
  line-height: 20px;
}

.not-found-estate-form-container {
  margin: 0 20%;
}

.not-found-estate-email {
  width: 336px;
  margin-bottom: 20px;
}

.not-found-estate-email .MuiInputLabel-outlined {
  font-size: 15px;
}

.not-found-estate-label-error {
  font-size: 11px;
  color: #ca0f4a;
  position: absolute;
  top: 75px;
  right: 0;
}

.not-found-estate-label-error img {
  margin-right: 5px;
}

.not-found-estate-content-button {
  margin-top: 40px;
  text-align: center;
}

.not-found-estate-button {
  width: 250px;
  height: 50px;
}

.not-found-estate-button-disabled {
  width: 250px;
  height: 50px;
}

.not-found-estate-button-progress {
  position: absolute;
  left: 70%;
  top: 10px;
}

/* Ipad */

@media (max-width: 769px) {
  .principal-not-found-estate-container {
    text-align: center;
  }
  .not-found-estate-container {
    background-image: none;
    background-color: #f9f9f9;
    padding-bottom: 50px;
  }
  .not-found-estate-content-title {
    text-align: center;
  }
  .not-found-estate-content-title p {
    color: #434362;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    font-size: 25px;
    font-weight: bold;
    line-height: 32px;
  }
  .not-found-estate-content-text-mark {
    margin: -15px -15px 20px 0px;
  }
  .not-found-estate-text-mark {
    width: 170px;
    height: 17px;
    background-color: #fdd44b;
    margin: 0px auto;
  }
  .not-found-estate-content-subtitle {
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .not-found-estate-content-subtitle p {
    color: #434362;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
  }
  .not-found-estate-form-container {
    margin: 0 20%;
  }
  .not-found-estate-input-container {
    margin-bottom: 15px;
  }
  .not-found-estate-button-container {
    margin: 30px 0px;
  }
  .not-found-estate-content-button {
    margin-top: 20px;
    position: relative;
    text-align: center;
  }
  
  .not-found-estate-button {
    width: 250px;
    height: 50px;
  }
  .not-found-estate-button-disabled {
    width: 250px;
    height: 50px;
  }
  .not-found-estate-button-progress {
    position: absolute;
    left: 70%;
    top: 10px;
  }
}

/* Mobile */

@media (max-width: 400px) {
  .not-found-estate-form-container {
    margin: 0 10%;
  }
}