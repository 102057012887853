.principal-find-estate-container {
  height: 100%;
  width: 100%;
  background-color: #f9f9f9;
  padding: 50px 0px;
}

.find-estate-container {
  margin-top: 40px;
  margin-bottom: 100px;
}
.find-estate-container .estates-container{
  margin-bottom: 40px !important;
  max-width: 700px !important;
}

.find-estate-container .footer{
  color: #707C89;
  font-size: 10px;
  max-width: 460px;
  text-align: center;
  margin: 40px auto;
  line-height: 20px;
}

.find-estate-content-arrow {
  margin-top: 13px;
  margin-bottom: -45px;
}

.find-estate-arrow-back {
  margin-left: -50%;
}

.find-estate-content-title {
  text-align: center;
}

.find-estate-content-title p {
  color: #434362;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 25px;
  font-weight: bold;
  line-height: 32px;
}

.find-estate-content-title p>span {
  background: -webkit-linear-gradient(top, transparent 60%, #fdd44b 40%);
}

.find-estate-content-text-mark {
  margin: -14px 190px 18px -30px;
}

.find-estate-text-mark {
  width: 125px;
  height: 17px;
  background-color: #fdd44b;
  margin-top: -15px;
  z-index: 1;
  margin: 0px auto;
}

.find-estate-content-subtitle {
  text-align: center;
  margin-top: 20px;
}

.find-estate-content-subtitle p {
  color: #434362;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: normal;
  line-height: 22px;
}

.find-estate-content-subtitle p>span {
  color: #434362;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: bold;
  line-height: 20px;
}

.find-estate-content-imgs {
  margin-top: 40px;
  display: flex;
  justify-content: center;
}

.find-estate-container-card {
  background-color: #fff;
  height: 130px;
  width: 150px;
  text-align: center;
  position: relative;
}

.find-estate-container-card .find-estate-arrow-done {
  position: absolute;
  top: 10px;
  right: 10px;
}

.find-estate-counter-container .arrows_container img {
  cursor: pointer;
}

.find-estate-container-card .find-estate-text {
  font-family: "Roboto";
  font-weight: bold;
  font-size: 10px;
  line-height: 12px;
  color: #383881;
  padding-top: 10px;
}

.find-estate-container-card-inactive {
  background-color: #f0f1f4;
  height: 130px;
  width: 150px;
  text-align: center;
  position: relative;
}

.find-estate-container-card-inactive .find-estate-arrow-done {
  position: absolute;
  top: 10px;
  right: 10px;
}

.find-estate-container-card-inactive .find-estate-text {
  font-family: "Roboto";
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: #383881;
  padding-top: 10px;
}

.find-estate-img {
  margin: 10px 10px 0px 10px;
}

.find-estate-content-link {
  margin-top: 50px;
  display: flex;
  justify-content: center;
  margin-top: 25px;
  align-items: center;
  cursor: pointer;
}

.find-estate-link {
  font-size: 13px;
  color: #383881;
  line-height: 20px;
  font-weight: 700;
  margin-right: 7px;
}

.find-estate-counter-container.active {
  display: flex;
  align-items: center;
  background-color: #f0f1f4;
  margin: 5px 10px 7px 10px;
  border-radius: 5px;
  height: 38px;
}

.find-estate-counter-container.inactive {
  display: flex;
  align-items: center;
  border: none;
  background-color: #fcfcfc;
  margin: 5px 10px 7px 10px;
  border-radius: 5px;
  height: 38px;
  opacity: 1;
}

.find-estate-counter-container .find-estate-counter-img.active {
  margin: 2px 30% 2px 10%;
  width: 33px;
  height: 16px;
  opacity: 1;
}

.find-estate-counter-container .find-estate-counter-img.inactive {
  margin: 2px 30% 2px 10%;
  width: 33px;
  height: 16px;
  opacity: 0.4;
}

.find-estate-counter-buttons {
  cursor: pointer;
  margin: 0px 3px;
}

.find-estate-counter-input.active {
  width: 10px;
  margin: 0px 3px;
  color: #383881;
  border: none;
  font-weight: bold;
  font-size: 16px;
  background-color: transparent;
}

.find-estate-counter-input.inactive {
  width: 10px;
  margin: 0px 3px;
  color: #c0bfda;
  border: none;
  font-weight: bold;
  font-size: 16px;
  background-color: transparent;
}

.find-estate-button-container {
  width: 100%;
  text-align: center;
  padding: 15px 0;
  background-color: #f9f9f9;
}

.find-estate-button-progress {
  position: absolute;
  left: 75%;
  top: 7px;
}

/* Ipad */

@media (max-width: 769px) {
  .principal-find-estate-container {
    text-align: center;
  }
  .find-estate-container {
    background-image: none;
    background-color: #f9f9f9;
    height: auto;
    
  }
  .find-estate-container .container {
    padding: 0 20px;
  }
  .find-estate-content-title {
    text-align: center;
  }
  .find-estate-content-title p {
    color: #434362;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    font-size: 25px;
    font-weight: bold;
    line-height: 32px;
  }
  .find-estate-content-text-mark {
    margin: -14px 27px 0px 0px;
  }
  .find-estate-text-mark {
    width: 165px;
    height: 17px;
    background-color: #fdd44b;
    margin: 0px auto;
  }
  .find-estate-content-subtitle {
    text-align: center;
    margin-top: 20px;
    margin-bottom: 30px;
  }
  .find-estate-content-subtitle p {
    color: #434362;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
  }
  .find-estate-content-imgs {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }
  .find-estate-container-card {
    background-color: #fff;
    height: 130px;
    width: 150px;
    text-align: center;
    position: relative;
  }
  .find-estate-container-card .find-estate-arrow-done {
    position: absolute;
    top: 10px;
    right: 10px;
  }
  .find-estate-container-card .find-estate-text {
    font-family: "Roboto";
    font-weight: bold;
    font-size: 10px;
    line-height: 12px;
    color: #383881;
    padding-top: 10px;
  }
  .find-estate-container-card-inactive {
    background-color: #f0f1f4;
    height: 130px;
    width: 150px;
    text-align: center;
    position: relative;
  }
  .find-estate-container-card-inactive .find-estate-arrow-done {
    position: absolute;
    top: 10px;
    right: 10px;
  }
  .find-estate-container-card-inactive .find-estate-text {
    font-family: "Roboto";
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: #383881;
    padding-top: 10px;
  }
  .find-estate-img {
    margin: 10px;
  }
  .find-estate-counter-container.active {
    display: flex;
    align-items: center;
    background-color: #f0f1f4;
    margin: 5px 10px 7px 10px;
    border-radius: 5px;
    height: 38px;
  }
  .find-estate-counter-container.inactive {
    display: flex;
    align-items: center;
    border: none;
    background-color: #fcfcfc;
    margin: 5px 10px 7px 10px;
    border-radius: 5px;
    height: 38px;
    opacity: 1;
  }
  .find-estate-counter-container .find-estate-counter-img.active {
    margin: 2px 30% 2px 10%;
    width: 33px;
    height: 16px;
    opacity: 1;
  }
  .find-estate-counter-container .find-estate-counter-img.inactive {
    margin: 2px 30% 2px 10%;
    width: 33px;
    height: 16px;
    opacity: 0.4;
  }
  .find-estate-counter-buttons {
    cursor: pointer;
    margin: 0px 3px;
  }
  .find-estate-counter-input.active {
    width: 10px;
    margin: 0px 3px;
    color: #383881;
    border: none;
    font-weight: bold;
    font-size: 16px;
    background-color: transparent;
  }
  .find-estate-counter-input.inactive {
    width: 10px;
    margin: 0px 3px;
    color: #c0bfda;
    border: none;
    font-weight: bold;
    font-size: 16px;
    background-color: transparent;
  }
  .find-estate-button-container {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    text-align: center;
    padding: 15px 0;
  }
  
  .find-estate-button-container button {
    margin: 0 20px;
    width: -webkit-fill-available;
  }
  .find-estate-button-progress {
    position: absolute;
    left: 75%;
    top: 7px;
  }
  .find-estate-content-link {
    margin-top: 50px;
    display: flex;
    justify-content: center;
    margin-top: 25px;
    align-items: center;
  }
  .find-estate-link {
    font-size: 13px;
    color: #383881;
    line-height: 20px;
    font-weight: 500;
    margin-right: 7px;
  }
}

/* Mobile */

/*
@media (max-width: 400px) {
  .find-estate-button-container {
    margin: 30px 50px;
  }
}*/