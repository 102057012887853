.card_payment-view .content-header-title p {
  font-size: 30px;
}

.card-payment-container {
  max-width: 390px;
  padding: 0 40px;
  margin: 40px auto 0 auto;
}

.card-payment-image-div {
  margin: 10px 0;
  text-align: right;
}

.card-payment-button-container {
  text-align: center;
}

@media (max-width: 769px) {
  .card-payment-container {
    margin-bottom: 90px;
  }
  
  .card-payment-button-container {
    text-align: center;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    margin: 0;
    padding: 15px 0;
    background-color: #f9f9f9;
  }

  .card-payment-button-container button {
    margin: 0 20px;
    width: -webkit-fill-available;
  }
}