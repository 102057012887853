.wrap-footer-organism {
  display: flex;
  justify-content: space-between;
}

.wrap-footer-organism div.section-container {
  align-items: center;
  justify-content: start;
  text-align: left;
  /* margin-right: 10rem; */
}

.wrap-footer-organism div.section-container:first-child {
  /* margin-right: 9rem !important; */
}

.wrap-footer-organism div.section-container:last-child {
  margin: 0 !important;
}

.wrap-footer-organism div.section-container img {
  width: 10em;
  height: auto;
}

.wrap-footer-organism div.section-container p.description {
  margin: 21px 0;
  max-width: 235px;
}

.wrap-footer-organism div.section-container p.title-ul {
  margin: 0 0 23px;
  color: #ffffff;
  font-size: 13px !important;
  font-weight: bold;
  font-size: 16px;
}

.wrap-footer-organism div.section-container p {
  text-align: left;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  color: #aaaaaa;
  line-height: 18px;
}

.wrap-footer-organism div.section-container ul li {
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  color: #aaaaaa;
  text-decoration: none;
  list-style: none;
  margin-bottom: 20px;
  cursor: pointer;
}

.wrap-footer-organism div.section-container ul a {
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  color: #aaaaaa;
  text-decoration: none;
  list-style: none;
  margin-bottom: 20px;
}

.wrap-footer-organism div.section-container .content-img {
  display: flex;
  flex-direction: row;
  margin-top: 28px;
  padding: 0;
}

.wrap-footer-organism div.section-container .content-img img {
  height: 42px;
  width: auto;
  cursor: pointer;
}

.wrap-footer-organism div.section-container .content-img img.margin {
  margin-right: 19px;
}

.wrap-footer-organism.disclaimer {
  grid-area: five;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  margin-top: 32px !important;
}

.wrap-footer-organism.disclaimer p {
  font-style: normal;
  font-weight: 300;
  font-size: 10px;
  color: #777777;
}

.wrap-footer-organism.disclaimer .after-year {
  display: none;
}

@media (min-width: 1365px) {
  .wrap-footer-organism div.section-container:first-child {
    margin-right: 10rem !important;
  }
}

@media (max-width: 768px) {
  .wrap-footer-organism {
    display: block;
  }
  .wrap-footer-organism div.section-container:first-child .description {
    display: none;
  }
  .wrap-footer-organism div.section-container p {
    margin-bottom: 2px;
    font-size: 16px;
    text-align: center !important;
  }
  .wrap-footer-organism div.section-container p.title-ul {
    margin: 23px 0 10px;
    font-size: 18px !important;
  }
  .wrap-footer-organism div.section-container {
    display: none;
    text-align: center;
  }
  .wrap-footer-organism div.section-container:first-child {
    display: block;
    margin: 0 !important;
  }
  .wrap-footer-organism div.section-container:last-child {
    display: block;
    align-items: center;
    margin: 0 !important;
  }
  .wrap-footer-organism div.section-container img {
    width: 119px;
  }
  .wrap-footer-organism .section-container:last-child {
    align-items: center;
    text-align: center;
  }
  .wrap-footer-organism .section-container .email {
    margin-bottom: 30px;
    font-size: 15px;
    font-weight: normal;
    color: #aaaaaa;
    text-decoration: none;
  }
  .wrap-footer-organism .section-container .email a {
    margin-bottom: 30px;
    font-size: 15px;
    font-weight: normal;
    color: #fff;
    text-decoration: none;
  }
  .wrap-footer-organism .section-container .content-img {
    justify-content: center;
    margin-bottom: 50px;
  }
  .wrap-footer-organism div.section-container .content-img img {
    height: 50px;
  }
  .wrap-footer-organism div.section-container .content-img img.margin {
    margin-right: 24px;
  }
  .wrap-footer-organism.disclaimer p {
    font-size: 12px;
    width: 180px;
  }
  .wrap-footer-organism.disclaimer .after-year {
    display: inline;
  }
  .wrap-footer-organism.disclaimer .before-year {
    display: none;
  }
}