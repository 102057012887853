.principal-register-car-detail-container {
  height: 100%;
  width: 100%;
  background-color: #f9f9f9;
  padding: 50px 0px;
}

.mb-15 {
  margin-bottom: 15px;
}

.pl-10 {
  padding-left: 10px;
}

.register-car-detail-container {
  width: 750px;
  margin: 0 auto;
  margin-bottom: 90px;
}

.register-car-detail-container.plateNotFound {
  width: 450px;
}

.register-car-detail-container .footer {
  color: #707C89;
  font-size: 10px;
  max-width: 460px;
  text-align: center;
  margin: 40px auto;
  line-height: 20px;
}

.register-car-detail-content-arrow {
  margin-top: 13px;
  margin-bottom: -45px;
}

.register-car-detail-arrow-back {
  margin-left: -60%;
}

.register-car-detail-content-title {
  text-align: center;
}

.register-car-detail-content-title p {
  color: #434362;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 25px;
  font-weight: bold;
  line-height: 32px;
}

.register-car-detail-content-title p>span {
  background: -webkit-linear-gradient(top, transparent 60%, #fdd44b 40%);
}

.register-car-detail-content-subtitle {
  text-align: center;
  margin-bottom: 30px;
}

.register-car-detail-content-subtitle p {
  color: #434362;
  font-family: "Roboto";
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.register-car-detail-content-text-mark {
  margin: -17px 236px 20px 0px;
}

.register-car-detail-text-mark {
  width: 90px;
  height: 17px;
  background-color: #fdd44b;
  margin-top: -15px;
  z-index: 1;
  margin: 0px auto;
}

.register-car-detail-card-container {
  margin-top: 35px;
}

.register-car-detail-card.active {
  box-shadow: none;
  background-color: #fff;
  border: solid #17788D;
}

.register-car-detail-card.inactive {
  box-shadow: none;
  background-color: #f0f1f4;
}

.register-car-detail-card:hover {}

.register-car-detail-card-content {
  height: 80px;
  padding: 0 !important;
  margin: 0;
  cursor: pointer;
}

.register-car-detail-card-title-images {
  text-align: center;
}

.register-car-detail-card-title-images .card-img-car {
  margin-left: 60px;
  padding-top: 10px;
  height: 18px;
  width: 31px;
}

.register-car-detail-card-title-images .card-img-icon {
  margin-left: 40px;
  padding-bottom: 7px;
  display: inline;
}

.register-car-detail-card-title {
  font-family: "Roboto";
  text-align: center;
  font-size: 14px;
  line-height: 19px;
  font-weight: 500;
  color: #434362;
}

.register-car-detail-card-text {
  font-family: "Roboto";
  text-align: center;
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  color: #707c89;
}

.register-car-detail-button-container {
  text-align: center;
}

.register-car-detail-button-container button {
  width: 250px;
}

.register-car-detail-section-container {
  background-color: #fff;
  position: relative;
  border-radius: 5px;
  padding: 20px 20px;
  margin: 15px 0px;
}

.register-car-detail-section-container.active {
  box-shadow: 0 0 0 3px #17788D;
}

.register-car-detail-section-container.inactive {
  box-shadow: none;
}

.register-car-detail-search-plates {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 20px;
}

.register-car-detail-search-plates .MuiInputBase-input {
  width: 50%;
}

.register-car-detail-search-plates-button {
  color: #434362;
  /* position: absolute; */
  /* left: 223px; */
  /* top: 28px; */
  margin-right: -8px;
  height: 40px;
  background: #f0f1f4;
  border: solid #f0f1f4;
  border-radius: 3px;
  width: 110px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.register-car-detail-search-plates-button:disabled {
  cursor: auto;
}

.register-car-detail-search-plates-button-loading {
  position: absolute;
  right: 15px;
  /* position: absolute;
  left: 223px;
  top: 26px;
  height: 44px;
  background: #5a549d;
  border: solid #5a549d;
  border-radius: 3px;
  width: 110px;
  display: flex;
  justify-content: center;
  align-items: center; */
}

.register-car-detail-search-plates-button-loading p {
  font-size: 14px;
  font-family: "Roboto";
  line-height: 19px;
  font-weight: 500;
  color: #fff;
  margin-right: 10px;
  margin-left: 10px;
}

.register-car-detail-search-plates-button p {
  font-size: 14px;
  font-family: "Roboto";
  line-height: 19px;
  font-weight: 500;
  color: #434362;
  margin-right: 10px;
  margin-left: 10px;
}

.register-car-detail-switch-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.register-car-detail-switch-text {
  margin: 0 0 10px 0 !important;
}

.register-car-detail-input-label .MuiFormControlLabel-label {
  /* font-size: 11px;
  font-family: "Roboto";
  font-weight: 500;
  line-height: 15px;
  color: #707c89;
  margin-right: 10px; */
}

.register-car-detail-switch-text .MuiFormControlLabel-label {
  font-size: 11px;
  font-family: "Roboto";
  font-weight: 500;
  line-height: 15px;
  color: #707c89;
  margin-right: 10px;
}

.register-car-detail-plates-container {
  margin-top: 20px;
  width: 100%;
}

.register-car-detail-plates-content {
  display: flex;
  padding: 5px 0;
}

.register-car-detail-plates-content:nth-child(odd) {
  background-color: #E9F2F4;
}

.register-car-detail-plates-content:nth-child(even) {
  background-color: #E9F2F4;
}

.register-car-detail-plates-title {
  font-size: 12px;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 16px;
  color: #646b72;
  margin: auto 15px;
  width: 50px;
}

.register-car-detail-plates-text {
  font-size: 12px;
  font-family: "Roboto";
  font-weight: bold;
  text-transform: uppercase;
  line-height: 18px;
  color: #444463;
  margin: auto 0;
}

.test .MuiOutlinedInput-input {
  padding: 5.5px 14px;
}

.register-car-detail-verification-container {
  background-color: #fff;
  border: solid #17788D;
  position: relative;
  border-radius: 5px;
  padding: 0px 20px;
  margin-bottom: 20px;
}

.register-car-detail-verification-content {
  margin: 0px 0px;
}

.register-car-detail-title {
  font-family: "Roboto";
  font-size: 11px;
  font-weight: 400;
  line-height: 15px;
  color: #707c89;
  /*margin: 10px 0px 15px 0px;*/
}

.register-car-detail-verification-box.inactive {
  background: #f0f1f4;
  border: none;
  margin-right: 2px;
  margin-left: 2px;
  border-radius: 5px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.register-car-detail-verification-box.active {
  background: #fff;
  border: solid #17788D 2px;
  margin-right: 2px;
  margin-left: 2px;
  border-radius: 5px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.register-car-detail-verification-text {
  font-family: "Roboto";
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  color: #434362;
}

.register-car-detail-select-content {
  /*padding-right: 10px;*/
}

.register-car-detail-service-container {
  background-color: #fff;
  border: solid #17788D;
  position: relative;
  border-radius: 5px;
  padding: 0px 20px 0px 10px;
  margin-bottom: 20px;
}

.register-car-detail-new {
  display: flex;
  align-items: center;
}

.register-car-detail-new-title {
  font-family: "Roboto";
  font-size: 11px;
  font-weight: 400;
  line-height: 15px;
  color: #707c89;
  margin-right: 10px;
}

.register-car-detail-service-container {
  background-color: #fff;
  border: solid #17788D;
  position: relative;
  border-radius: 5px;
  padding: 0px 20px 0px 10px;
  margin-bottom: 20px;
}

.register-car-detail-insurance-container {
  background-color: #fff;
  border: solid #17788D;
  position: relative;
  border-radius: 5px;
  padding: 0px 20px 0px 20px;
  margin-bottom: 20px;
}

.register-car-detail-button-container {
  margin: 50px 30px 0px 30px;
}

.register-car-detail-button {
  width: 250px;
  height: 50px;
}

.register-car-detail-button-disabled {
  width: 250px;
  height: 50px;
}

.register-car-detail-button-progress {
  position: absolute;
  left: 75%;
  top: 10px;
}

.register-car-detail-buttons-content {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.register-car-detail-buttons-content button {
  background-color: #F0F1F4;
  /* border: solid 1px #F0F1F4; */
  border: none;
  border-radius: 5px !important;
  width: 100%;
  margin: 0 10px;
  height: 40px;
}

.register-car-detail-buttons-content button:first-child {
  margin-left: 0;
}

.register-car-detail-buttons-content button:last-child {
  margin-right: 0;
}

.register-car-detail-buttons-content button.Mui-selected {
  background-color: #FFF !important;
  box-shadow: 0 0 0 3px #17788D;
}

.register-car-detail-select-selected .MuiOutlinedInput-notchedOutline {
  border: 3px solid #17788D !important;
}

.register-car-detail-circulation-card-content .register-car-detail-buttons-content {
  justify-content: left;
}

.register-car-detail-circulation-card-content .register-car-detail-buttons-content button {
  width: 60px;
}

.register-car-detail-insurance-content .register-car-detail-buttons-content {
  justify-content: left;
}

.register-car-detail-insurance-content .register-car-detail-buttons-content button {
  width: 60px;
}

.register-car-detail-service-km {
  /* margin-top: 20px !important; */
}
.last-service-button{
  font-size: 12px !important;
}

/* mobile */

@media (max-width: 768px) {
  .last-service-buttons.mobile{
    margin-top: 10px;
  }
  .last-service-buttons.mobile .last-service-button{
    margin-right: 10px !important;
  }
  .register-car-detail-service-km {
    margin-top: 0 !important;
  }
  .register-car-detail-container, .register-car-detail-container.plateNotFound  {
    width: auto;
    display: flex;
    justify-content: center;
  }
  .register-car-detail-container>form {
    margin: 0px 15px;
  }
  .register-car-detail-content-title {
    text-align: center;
  }
  .register-car-detail-content-title p {
    color: #434362;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    font-size: 25px;
    font-weight: bold;
    line-height: 32px;
  }
  .register-car-detail-content-text-mark {
    margin: -15px 0px 15px 0px;
  }
  .register-car-detail-text-mark {
    width: 135px;
    height: 15px;
    background-color: #fdd44b;
    margin-top: -15px;
    z-index: 1;
    margin: 0px auto;
  }
  .register-car-detail-section-cards {
    /* width: 100%;
  padding-top: 25px;
  min-height: 200px; */
    display: flex;
    margin-bottom: 10px;
    overflow: auto;
  }
  .register-car-detail-card-container {
    /* width: 30%;
  margin-bottom: 15px;
  float: left;
  margin-right: 15px; */
    margin: 10px 5px 10px 5px;
  }
  .register-car-detail-card-container:first-child {
    /* width: 30%;
  margin-bottom: 15px;
  float: left;
  margin-right: 15px; */
    margin: 10px 5px 10px 0px;
  }
  .register-car-detail-card.active {
    box-shadow: none;
    background-color: #fff;
    border: solid #17788D;
  }
  .register-car-detail-card.inactive {
    box-shadow: none;
    background-color: #f0f1f4;
  }
  .register-car-detail-card:hover {}
  .register-car-detail-card-content {
    height: 80px;
    width: 150px;
    padding: 0 !important;
    margin: 0;
    cursor: pointer;
  }
  .register-car-detail-card-title-images {
    text-align: center;
  }
  .register-car-detail-card-title-images .card-img-car {
    margin-left: 45px;
    padding-top: 10px;
    height: 18px;
    width: 31px;
  }
  .register-car-detail-card-title-images .card-img-icon-aprove {
    margin-left: 25px;
    padding-bottom: 7px;
    display: inline;
  }
  .register-car-detail-card-title-images .card-img-icon-declined {
    display: none;
    margin-left: 25px;
    padding-bottom: 7px;
  }
  .register-car-detail-card-title {
    font-family: "Roboto";
    text-align: center;
    font-size: 14px;
    line-height: 19px;
    font-weight: 500;
    color: #434362;
  }
  .register-car-detail-card-text {
    font-family: "Roboto";
    text-align: center;
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    color: #707c89;
  }
  .register-car-detail-button-container {
    margin: 0 !important;
    z-index: 1;
    text-align: center;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    margin: 0;
    padding: 15px 0;
    background-color: #f9f9f9;
  }
  .register-car-detail-button-container button{
   margin: 0 20px;
   width: -webkit-fill-available;
  }
  /* .register-car-detail-button-container {
    text-align: center;
  }
  .register-car-detail-button-container button {
    width: 250px;
  } */
  .register-car-detail-section-container {
    background-color: #fff;
    box-shadow: 0 0 0 3px #17788D;
    position: relative;
    border-radius: 5px;
    padding: 20px;
  }
  .register-car-detail-section-container.active {
    box-shadow: 0 0 0 3px #17788D;
  }
  .register-car-detail-section-container.inactive {
    box-shadow: none;
  }
  .register-car-detail-search-plates {}
  .register-car-detail-search-plates .MuiInputBase-input {
    width: 50%;
  }
  .register-car-detail-search-plates-button {
    /* position: absolute; */
    /* left: 267px; */
    /* top: 82px; */
    height: 44px;
    background: #f0f1f4;
    border: solid #f0f1f4;
    border-radius: 3px;
    width: 105px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .register-car-detail-search-plates-button p {
    font-size: 14px;
    font-family: "Roboto";
    line-height: 19px;
    font-weight: 500;
    color: #434362;
    margin-right: 10px;
    margin-left: 10px;
  }
  .register-car-detail-search-plates-button-loading p {
    font-size: 14px;
    font-family: "Roboto";
    line-height: 19px;
    font-weight: 500;
    color: #fff;
    margin-right: 10px;
    margin-left: 10px;
  }
  .register-car-detail-switch-container {
    display: flex;
    margin-right: 20px;
    align-items: flex-start;
  }
  .register-car-detail-switch-text {
    font-size: 11px;
    font-family: "Roboto";
    font-weight: 500;
    line-height: 15px;
    color: #707c89;
    margin: 0px !important;
  }
  .register-car-detail-plates-container {
    margin: 20px 0px;
  }
  .register-car-detail-plates-content {
    display: flex;
  }
  .register-car-detail-plates-content:nth-child(odd) {
    background-color: #E9F2F4;
    height: 26px;
  }
  .register-car-detail-plates-content:nth-child(even) {
    background-color: #E9F2F4;
    height: 26px;
  }
  .register-car-detail-plates-title {
    font-size: 12px;
    font-family: "Roboto";
    font-weight: 400;
    line-height: 16px;
    color: #646b72;
    margin-left: 15px;
    margin: auto 15px;
  }
  .register-car-detail-plates-text {
    font-size: 12px;
    font-family: "Roboto";
    font-weight: bold;
    text-transform: uppercase;
    line-height: 18px;
    color: #444463;
    margin: auto 0;
  }
  .register-car-detail-content-subtitle {
    text-align: center;
    margin-bottom: 30px;
  }
  .register-car-detail-content-subtitle p {
    color: #434362;
    font-family: "Roboto";
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }
  .test .MuiOutlinedInput-input {
    padding: 5.5px 14px;
  }
  .register-car-detail-verification-container {
    background-color: #fff;
    border: solid #17788D;
    position: relative;
    border-radius: 5px;
    padding: 0px 20px;
    margin-bottom: 20px;
  }
  .register-car-detail-verification-content {
    margin: 0px 0px;
  }
  .register-car-detail-title {
    font-family: "Roboto";
    font-size: 11px;
    font-weight: 400;
    line-height: 15px;
    color: #707c89;
  }
  .register-car-detail-verification-box.inactive {
    background: #f0f1f4;
    border: none;
    margin: 0px 2px;
    border-radius: 5px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .register-car-detail-verification-box.active {
    background: #fff;
    border: solid #17788D 2px;
    margin: 0px 2px;
    border-radius: 5px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .register-car-detail-validation-box.inactive {
    background: #f0f1f4;
    border: none;
    margin: 0px 15px 0px 0px;
    border-radius: 5px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .register-car-detail-validation-box.active {
    background: #fff;
    border: solid #17788D 2px;
    margin: 0px 15px 0px 0px;
    border-radius: 5px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .register-car-detail-verification-text {
    font-family: "Roboto";
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
    color: #434362;
  }
  /* .register-car-detail-select-content {
  margin: 0px 10px 0px 0px;
} */
  .register-car-detail-service-container {
    background-color: #fff;
    border: solid #17788D;
    position: relative;
    border-radius: 5px;
    padding: 0px 20px 0px 10px;
    margin-bottom: 20px;
  }
  .register-car-detail-new {
    display: flex;
    align-items: center;
  }
  .register-car-detail-new-title {
    font-family: "Roboto";
    font-size: 11px;
    font-weight: 400;
    line-height: 15px;
    color: #707c89;
    margin-right: 10px;
  }
  .register-car-detail-service-container {
    background-color: #fff;
    border: solid #17788D;
    position: relative;
    border-radius: 5px;
    padding: 0px 20px 0px 10px;
    margin-bottom: 20px;
  }
  .register-car-detail-insurance-container {
    background-color: #fff;
    border: solid #17788D;
    position: relative;
    border-radius: 5px;
    padding: 0px 20px 0px 20px;
    margin-bottom: 20px;
  }
  .register-car-detail-button-container {
    margin: 50px 30px 0px 30px;
  }
  .register-car-detail-content-button {}
  .register-car-detail-button {
    width: 320px;
    height: 50px;
  }
  .register-car-detail-button-disabled {
    width: 320px;
    height: 50px;
  }
  .register-car-detail-button-progress {
    position: absolute;
    left: 70%;
    top: 10px;
  }
}