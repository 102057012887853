.privacy-notice-page{
  padding: 0 18%;
  margin-bottom: 60px;
}
.register-content-title {
  text-align: center;
  position: relative;
}

.register-content-title p {
  color: #434362;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 25px;
  font-weight: bold;
  line-height: 35px;
}

.register-content-title .register-arrow-back {
  position: absolute;
  top: 50%;
  left: 10%;
}

.register-content-text-mark {
  margin: -59px 0px 0px 87px;
}

.register-text-mark {
  width: 130px;
  height: 17px;
  background-color: #fdd44b;
  margin-top: -15px;
  z-index: 1;
  margin: 0px auto;
}

/* Ipad */

@media (max-width: 769px) {
  .principal-register-container {
    text-align: center;
  }
  .register-container {
    background-image: none;
    background-color: #f9f9f9;
  }
  .register-content-title {
    text-align: center;
  }
  .register-content-title p {
    color: #434362;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    font-size: 25px;
    font-weight: bold;
    line-height: 32px;
  }
  .register-content-text-mark {
    margin: -79px 0px 79px 85px;
  }
  
  .register-content-buttons {
    margin: 0px 20%;
  }
}

/* Mobile */

@media (max-width: 500px) {
  .register-content-buttons {
    margin: 0px 50px;
  }
}