html {
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  text-align: left;
  background-color: #fff;
}

body.ready {
  background-color: #f9f9f9;
}

html, body {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

* {
  margin: 0px;
  padding: 0px;
  font-family: "Roboto", sans-serif;
}

/* .App {
  background-color: #f9f9f9;
  min-height: 100vh;
}

.main-section {
  background-color: #f9f9f9;
} */

article.principal {
  text-align: center;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: center center;
  background-size: cover;
  padding: 2rem 1rem !important;
}

article.principal .wrap-general {
  max-width: 1750px;
  margin: 0 auto;
}

article.principal>.wrap-general>.general-content-title {
  display: flex;
  justify-content: center;
  margin-bottom: 24px;
}

article.principal>.wrap-general>.general-content-title p {
  font-style: normal;
  font-weight: bold;
  font-size: 34px;
  color: #434362;
}

article.principal>.wrap-general>.general-content-subtitle {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
}

article.principal>.wrap-general>.general-content-subtitle p {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: #434362;
  line-height: 23px;
  /*width: 40%;*/
}

footer.principal {
  text-align: center;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: top center;
  background-size: cover;
  margin-top: 60px;
  background-color: #232443;
}

footer.principal .wrap-general {
  max-width: 1750px;
  margin: 0 auto;
}

.MuiFormHelperText-root.Mui-error svg.svg-inline--fa {
  margin-right: 5px;
  vertical-align: -0.2em;
}

.alerta-purple-button {
  width: 250px;
  height: 50px;
  background-color: #5a549d !important;
  color: #fff !important;
}

.alerta-purple-button.small {
  height: 30px;
  font-size: 10px;
}

.alerta-purple-button.short {
  width: 150px;
}

.alerta-purple-button:disabled {
  background-color: #D3D3D3 !important;
}

.alerta-purple-button-text {
  color: #fff;
}

.alerta-purple-button-progress {
  color: #fff !important;
  position: absolute;
  left: 80%;
  top: 10px;
}

.alerta-white-button {
  width: 250px;
  height: 50px;
  background-color: #fff !important;
  border: 2px solid #434362 !important;
}

.alerta-white-button.small {
  height: 30px;
  font-size: 10px;
}

.alerta-white-button.short {
  width: 150px;
}

.alerta-white-button:disabled {
  background-color: #D3D3D3 !important;
}

.alerta-white-button-text {
  color: #434362;
}

.alerta-white-button-progress {
  color: #434362 !important;
  position: absolute;
  left: 80%;
  top: 10px;
}

.text-center {
  text-align: center;
}

.MuiOutlinedInput-root {
  background-color: #fff;
}

.MuiFormHelperText-root {
  margin: 0 !important;
  background-color: transparent;
  padding-top: 5px;
  text-align: right !important;
}

.MuiButton-root {
  text-transform: none !important;
}

.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #5A549D !important;
}

.MuiInputLabel-root.Mui-focused {
  color: #5A549D !important;
}

.Mui-error {
  color: #CA0F4A !important;
  border-color: #CA0F4A !important;
}

.MuiMenu-root .MuiPaper-elevation {
  max-height: 260px !important;
}
.MuiToggleButton-root{
  text-transform: none !important;
}

.view-loading-container {
  margin: 60px auto;
  text-align: center;
}

.view-loading-container span {
  color: #5A549D;
}

@media (min-width: 769px) {
  .mobile {
    display: none !important;
  }

  .container {
    margin: 0 auto !important;
    padding: 0 60px !important;
    max-width: 990px !important;
  }

  @keyframes animatetop {
    from {
      top: -90px;
      opacity: 0;
    }

    to {
      top: 0;
      opacity: 1;
    }
  }

  article.principal {
    padding-top: 2rem;
    padding-bottom: 4rem;
    padding-left: 3rem;
    padding-right: 3rem;
  }

  footer.principal {
    padding-top: 4rem;
    padding-bottom: 2rem;
    padding-left: 0;
    padding-right: 0;
  }
}

@media (max-width: 768px) {
  .desktop {
    display: none !important;
  }

  article.principal {
    padding-top: 0.5rem !important;
    padding-bottom: 1.6rem !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  footer.principal {
    padding-top: 3.5rem;
    padding-bottom: 1.5rem;
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media (max-width: 768px) {
  article.principal {
    padding-top: 0.5rem;
    padding-bottom: 1.6rem;
    padding-left: 20px;
    padding-right: 20px;
  }

  footer.principal {
    padding-top: 4rem;
    padding-bottom: 3rem;
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 90px
  }
}

@media (max-width: 768px) {
  article.principal>.wrap-general>.general-content-title {
    margin-bottom: 18px;
  }

  article.principal>.wrap-general>.general-content-title p {
    font-size: 25px;
  }

  article.principal>.wrap-general>.general-content-subtitle {
    margin-bottom: 30px;
  }

  article.principal>.wrap-general>.general-content-subtitle p {
    font-size: 15px;
    line-height: 18px;
    width: 100%;
  }

  .fixed-button-container {
    text-align: center;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    margin: 0;
    padding: 15px 0;
    background-color: #f9f9f9;
    z-index: 1;
  }
  .fixed-button-container button {
    margin: 0 20px;
    width: -webkit-fill-available;
  }
}