.maintenance-service-section {
  margin-top: 10px;
}

.maintenance-service-section .maintenance-service-button {
  background-color: #F0F1F4;
  border: none;
  border-radius: 5px !important;
  margin-right: 10px;
  padding: 5px 20px;
  font-size: 14px;
  color: #434362;
}

.maintenance-service-section .maintenance-service-input-selected .MuiOutlinedInput-notchedOutline {
  border: 3px solid #17788D !important;
}