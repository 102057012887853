.home-why-section {
    margin-bottom: 120px;
}

.home-why-section .title {
    color: #434362;
    font-size: 35px;
    font-weight: bold;
    margin-bottom: 40px;
    line-height: 50px;
    max-width: 400px;
}

.home-why-section .subtitle {
    color: #434362;
    font-size: 16px;
    max-width: 370px;
    line-height: 25px;
}

.home-why-section .images {
    display: flex;
    flex-direction: row;
    margin: 5px 0;
}

.home-why-section .image-content {
    margin: 30px 15px 30px 0px;
    padding: 7px 7px 3px 7px;
}

.home-why-section .image-content.whats {
    background-color: #399F35;
    border-radius: 25px;
}

.home-why-section .image-content.sms {
    background-color: #1E88E5;
    border-radius: 25px;
}

.home-why-section .image-content.email {
    background-color: #5A549D;
    border-radius: 25px;
}

.home-why-section .note {
    font-size: 12px;
    color: #707C89;
    text-align: center;
    max-width: 600px;
    margin: 40px auto;
    line-height: 25px;
}

@media (max-width: 768px) {
    .home-why-section .title {
        text-align: center;
    }

    .home-why-section .subtitle {
        text-align: center;
    }

    .home-why-section .images {
        justify-content: center;
    }

    .home-why-section .alerta-purple-button {
        margin: auto;
        margin-bottom: 60px;
    }

    .home-why-section .side-image {
        width: 100%;
    }
    .home-why-section {
        margin-bottom: 90px;
    }
}