.payment-section-oxxo-message{
    color: #434362;
    text-align: center;
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 40px;
}

.payment-section-oxxo-steps{
    text-align: center;
    max-width: 300px;
    margin: 20px auto;
}