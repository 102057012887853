.home-single-report .space{
  margin-bottom: 120px;
}

.home-single-report .background{
  background-color: #F0F0F0;
  position: absolute;
  left: 0px;
  width: 100%;
}
.home-single-report .content{
  padding: 60px;
  max-width: 1050px;
  margin: auto;
}

.home-single-report .title {
  text-align: center;
  font-style: normal;
  font-weight: bolder;
  font-size: 35px;
  color: #434362;
  margin: auto;
  margin-bottom: 24px;
  line-height: 40px;
}

.home-single-report .subtitle {
  text-align: center;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  color: #434362;
  line-height: 30px;
  max-width: 640px;
  margin: auto;
  margin-bottom: 20px;
}

@media (max-width: 769px) {
  .home-single-report .content{
    padding: 60px 20px;
  }
}