.remove-alert-dialog-content {
    padding: 15px;
    text-align: center;
    font-family: 'Roboto';
    max-width: 400px;
}

.remove-alert-dialog .close-icon {
    margin: 15xp;
    cursor: pointer;
}

.remove-alert-dialog-title {
    font-weight: bold;
    font-size: 15px;
    max-width: 270px;
    margin: auto;
}

.remove-alert-dialog-subtitle {
    font-size: 13px;
    font-weight: 400;
    max-width: 270px;
    line-height: 25px;
    margin: auto;
}

.remove-alert-dialog-button-container {
    padding-top: 40px;
}

.remove-alert-dialog-button-container button {
    margin-bottom: 10px;
}

@media (max-width: 768px) {
    .remove-alert-dialog-content {
        padding: 30px;
    }
}