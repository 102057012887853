.fotocivicas-section .main-label {
    line-height: 15px;
    font-size: 10px;
}

.fotocivicas-section .current-points {
    padding-right: 40px;
    text-align: right;
}

.fotocivicas-section .current-points .title {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
}

.fotocivicas-section .current-points .title .label {
    font-size: 12px;
    font-weight: 400;
    color: #646B72;
}

.fotocivicas-section .current-points .title .points {
    color: #fff;
    font-size: 25px;
    border-radius: 25px;
    padding: 5px 5px;
    margin-bottom: 10px;
    width: 30px;
    text-align: center;
}

.fotocivicas-section .current-points .label {
    font-size: 12px;
    text-align: right;
    font-weight: bold;
}

.fotocivicas-section .current-points .text {
    color: #646B72;
    font-size: 10px;
    text-align: right;
}

.fotocivicas-section .current-points .status {
    color: #17788D;
    font-size: 12px;
    text-align: right;
    font-weight: bold;
    margin: 25px 0px;
    width: 100%;
}

.fotocivicas-section .sanctions .alerta-purple-button {
    height: 35px;
    width: 200px;
}

.fotocivicas-section .sanctions .alerta-purple-button-text {
    font-size: 13px;
}

.fotocivicas-section .points-footer {
    color: #707C89;
    font-size: 10px;
    text-align: center;
    max-width: 275px;
    margin: 20px auto 20px auto;
}