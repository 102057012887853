.premium-section {
  margin-top: 40px;
  color: #434362;
}
.premium-section .premium-container {
  max-width: 700px;
  margin: auto;
  background-color: #ffffff;
  border: 2px solid #17788d;
  border-radius: 5px;
  padding: 20px 0;
}
.premium-section .premium-img {
  margin: auto;
}
.premium-section .premium-title {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 10px;
}

.premium-section .premium-title span {
  margin-right: 10px;
}

.premium-section .premium-subtitle {
  font-size: 11px;
  line-height: 18px;
}

.premium-section .premium-subtitle span{
  font-weight: bold;
  color: #17788d;
  margin-left: 10px;
  cursor: pointer;
}

.premium-section .premium-price-container{
  text-align: right;
  padding-right: 30px;
}

.premium-section .premium-price{
  color: #17788d;
  font-size: 14px;
  font-weight: bold;
  margin: 10px 0;
}

.premium-section .premium-button{
  color: #ffffff;
  background-color: #17788d;
  padding: 10px 30px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

@media (max-width: 769px) {
  .premium-section .premium-container {
    padding-right: 20px;
  }
  .premium-section .premium-subtitle span{
    margin-left: 0;
  }
  .premium-section .premium-price-container{
    margin-top: 20px;
    text-align: left;
  }
}