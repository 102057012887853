.limit-vehicle-estate-dialog-content {
    padding: 30px 60px;
    text-align: center;
    font-family: 'Roboto';
}

.limit-vehicle-estate-dialog-title {
    font-weight: bold;
}

.limit-vehicle-estate-dialog-subtitle {
    font-weight: 400;
    max-width: 315px;
    line-height: 25px;
}

.limit-vehicle-estate-dialog-button-container {
    padding-top: 40px;
}

@media (max-width: 768px) {
    .limit-vehicle-estate-dialog-content {
        padding: 30px;
    }
}