.how-section {
    margin-bottom: 120px;
}

.principal-how-organism {
    text-align: center;
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: center center;
    background-size: cover;
    padding: 0;
}

.principal-how-organism .wrap-general {
    max-width: 1750px;
    margin: 0 auto;
}

.wrap-how-organism .title {
    font-style: normal;
    font-weight: bolder;
    font-size: 35px;
    color: #434362;
    margin: auto;
    margin-bottom: 24px;
    line-height: 40px;
}

.wrap-how-organism .subtitle {
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    color: #434362;
    line-height: 30px;
    max-width: 595px;
    margin: auto;
}

.wrap-how-organism .text.big {
    font-family: 'Roboto';
    font-style: normal;
    font-size: 23px;
    color: #434362;
    line-height: 30px;
    text-align: left;
    margin: 60px 0px 24px 0px;
}

.wrap-how-organism .text {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    color: #434362;
    line-height: 30px;
    text-align: left;
    margin: 60px 0px 30px 0px;
}

.container-note .note {
    font-family: "Roboto";
    font-weight: 400;
    font-size: 12px;
    line-height: 25px;
    color: #707c89;
    margin: auto;
    max-width: 460px;
}

.wrap-how-organism .aditional-services {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    color: #434362;
    line-height: 30px;
    text-align: left;
    margin: 15px 20px 15px 20px;
}

.wrap-how-organism .content-service {
    display: flex;
    justify-content: space-between;
}

.wrap-how-organism .content-service .service-card:first-child {
    margin-left: 0px;
}

.wrap-how-organism .content-service .service-card:last-child {
    margin-right: 0px;
}


.wrap-how-organism .service-card {
    width: 100%;
    border-radius: 5px;
    float: left;
    height: 210px;
}

.wrap-how-organism .service-card-blank {
    width: 226px;
    background-color: #f9f9f9;
    border-radius: 5px;
    margin: 10px;
    border-left: solid 7px #f9f9f9;
    float: left;
    height: 210px;
}

.wrap-how-organism .service-card .title {
    font-family: "Roboto";
    font-size: 15px;
    font-weight: bold;
    line-height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 50px;
    max-width: 160px;
}

.wrap-how-organism .service-card .subtitle {
    color: #707c89;
    font-family: "Roboto";
    font-size: 12px;
    font-weight: 400;
    line-height: 21px;
    display: flex;
    justify-content: center;
    margin: auto;
    margin-top: -10px;
    max-width: 155px;
}

.wrap-how-organism .message {
    font-style: normal;
    font-weight: normal;
    font-size: 25px;
    color: #434362;
    margin-bottom: 43px;
}

.wrap-how-organism .how-section-steps {
    margin-bottom: 90px;
    /*display: flex;*/
    /*justify-content: center;*/
}
.wrap-how-organism > .wrap-step-title {
    margin-bottom: 50px;
    /*display: flex;*/
    /*justify-content: center;*/
}

.wrap-how-organism > .wrap-steps > .content-steps {
    max-width: 1190px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
    margin-bottom: 43px;
}

.wrap-how-organism > .wrap-steps > .content-steps.services {
    justify-content: space-between;
}


.wrap-carousel-step {
    display: none;
}

.wrap-how-organism .content-subtitle {
    margin-bottom: 60px;
}


@media (max-width: 980px) {
    .principal-how-organism {
        margin-top: 0px;
        padding-top: 0rem !important;
        padding-bottom: 0rem !important;

    }

    .wrap-carousel-step {
        display: block;
        display: flex;
        justify-content: flex-start;
        /* padding-left: 20px; */
        padding-bottom: 0rem;
        margin: 0 auto;
        width: 80%;
        margin-bottom: 20px;
    }

    .content-carousel-step {
        width: 100%;
        /* display: flex; */
        justify-content: center;
        text-align: center;
    }

    .content-carousel-step .text {
        font-size: 20px;
        font-weight: 500;
        line-height: 30px;
        font-family: "Roboto";
        color: #434362;
        padding: 0px 5px;
        margin-top: 30px;
    }

    .container-note .note {
    }

    .content-carousel-step .text-aditional-service {
        font-size: 20px;
        font-weight: 500;
        line-height: 30px;
        font-family: "Roboto";
        color: #434362;
        padding: 0px 5px;
    }

    .service-card-mobile .title {
        font-size: 15px;
        font-weight: bold;
        line-height: 20px;
        font-family: "Roboto";
        padding: 20px 0px;
        color: #434362;
    }

    .service-card-mobile .subtitle {
        font-size: 12px;
        font-weight: 400;
        line-height: 17px;
        font-family: "Roboto";
        color: #707c89;
        padding: 0px 5px;
    }

    .content-carousel-step .slick-dots li button:before {
        width: 40px;
        height: 40px;
        font-size: 11px;
        opacity: 1;
        color: rgba(216, 216, 216, 1);
    }

    .content-carousel-step .slick-dots li.slick-active button:before {
        opacity: 1;
        color: rgba(170, 170, 170, 1);
    }
}

@media (max-width: 768px) {
    .how-section {
        margin-bottom: 90px;
    }
    .wrap-how-organism .wrap-step-title {
        margin-bottom: 20px;
        text-align: center;
    }

    .principal-how-organism {
        margin-bottom: 90px;
    }

    .wrap-how-organism .content-title {
        margin-top: -25px;
        margin-bottom: 30px;
    }

    .wrap-how-organism .content-title .title {
        font-size: 35px;
        margin-bottom: 18px;
        font-weight: bold;
        color: #434362;
    }

    .wrap-how-organism .content-subtitle {
        margin-bottom: 35px;
    }

    .wrap-how-organism .content-subtitle .subtitle {
        font-size: 16px;
        line-height: 24px;
        font-weight: normal;
        color: #434362;
        max-width: 300px;
        margin: 0 auto;
    }

    .wrap-how-organism .text {
        margin: 0;
        font-weight: 500;
        font-size: 20px;
        text-align: center !important;
    }

    .service-card-mobile:nth-child(odd) {
        margin-right: 11px;
    }

    .service-card-mobile:nth-child(even) {
        margin-left: 11px;
    }

    .wrap-how-organism .content-message .message {
        font-size: 15px;
        margin-bottom: 30px;
    }

    .principal-how-organism .wrap-alert-message-molecules {
        margin: 20px 0 !important;
        margin-bottom: 60px !important;
    }

    .wrap-how-organism .wrap-steps {
        /* display: none; */
    }

    .wrap-how-organism > .wrap-steps > .content-steps {
        flex-direction: column;
        align-items: center;
        margin-bottom: 10px;
    }

    .wrap-how-organism .add_services {
        display: flex;
        flex-direction: row;
    }

    .wrap-how-organism .add_services > .wrap-steps > .content-service {
        display: flex;
        flex-direction: column;
    }

    .wrap-how-organism .service-card {
        /* width: 130px; */
        height: 170px;
        margin: 0;
        /* border-radius: 5px;
      margin: 10px;
      float: left;
      height: 210px; */
    }

    .wrap-how-organism .service-card .title {
        padding: 20px 5px;
        margin: 0;
    }

    .wrap-how-organism .service-card .subtitle {
        padding: 0 10px;
        margin: 0;
    }
}