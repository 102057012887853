.edit-alert-container {
  margin: 40px auto 80px auto;
  max-width: 800px;
  text-align: center;
  color: #434362;
}

.edit-alert-form-container {
  width: 100%;
  margin-right: 40px;
}

.edit-alert-content-input {
  margin-bottom: 20px;
}
.edit-alert-content-input:last-child {
  margin-bottom: 0;
}

.edit-alert-content-input .MuiFormHelperText-root {
  margin: 0;
  background-color: transparent;
  padding-top: 5px;
  text-align: right;
}

.edit-alert-content-input .MuiInputLabel-outlined {
  font-size: 15px;
}

.edit-alert-button-container {
  margin-top: 40px;
}

.edit-alert-label-error {
  font-size: 11px;
  color: #ca0f4a;
  position: absolute;
  top: 60px;
  right: 0%;
}

.edit-alert-container .additional-user-container {
  padding: 35px;
  background-color: #F2F2F2;
  text-align: left;
}
.edit-alert-container .additional-user-form {
  background-color: #F2F2F2;
  padding: 20px 35px;
}
.edit-alert-container .additional-user-form-container {
  text-align: left;
}

.edit-alert-container .additional-user-form-container .icon{
  float: right;
  color: #A8A8A8;
  cursor: pointer;
}

.edit-alert-container .additional-user-container p {
  margin-bottom: 20px;
  line-height: 20px;
  color: #434362;
}

.edit-alert-container p.title {
  font-size: 14px;
  font-weight: bold;
  text-align: left;
}

.edit-alert-container p.text {
  font-size: 12px;
  text-align: left;
}

.edit-alert-container .additional-user-form-container p.title {
  margin-bottom: 20px;
 
  font-size: 14px;
}

.edit-alert-container .additional-user-form-container p.text {
  margin-top: 20px;
  margin-bottom: 0;
  color: #646B72;
  line-height: 20px;
}

@media (max-width: 769px) {}