.share-alerta-dialog-content {
    padding: 30px 30px;
    text-align: left;
    font-family: 'Roboto';
}

.close-icon-container {
    text-align: right;
    color: #A8A8A8;
    float: right;
    /* position: absolute;
    left: 270px;
    top: 15px; */
}

.close-icon-container .close-icon {
    cursor: pointer;
}

.share-alerta-dialog-content .title {
    color: #434362;
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 20px;
}

.share-alerta-dialog-content .share-icons {
    margin-bottom: 20px;
}

.share-alerta-dialog-content .share-icons button {
    width: 60px;
}

.share-alerta-dialog-content .share-icons .icon-text {
    display: block;
    font-size: 10px;
}

.share-alerta-dialog-content .link-container {
    background-color: #F9F9F9;
    padding: 10px;
    border-radius: 5px;
    display: flex;
}

.share-alerta-dialog-content .link-container .note {
    color: #7E7E7E;
    font-size: 10px;
    line-height: 20px;
}

.share-alerta-dialog-content .link-container .link {
    color: #5a549f;
    font-size: 12px;
    font-weight: bold;
    word-break: break-all;
    width: 180px;
}

.share-alerta-dialog-content .link-container .copy-icon-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    margin: 0 10px;
}

.share-alerta-dialog-content .link-container .copy-icon {
    color: #7E7E7E;
    cursor: pointer;
}



@media (max-width: 768px) {}