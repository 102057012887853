div[class*="alerta/"] .content-header {
  padding: 0;
}
div[class*="alerta/"] .service-overflow {
  height: 100% !important;
}
.vehicle-service-page{
  padding: 0 20px;
  max-width: 500px;
  margin: 0 auto;
}
.vehicle-service-back{
  color: #0A65BF;
  cursor: pointer;
}
.vehicle-service-back .icon{
  margin-right: 10px;
}
.view-service-controls {
  margin: 20px 0;
  font-size: 14px;
}
.view-service-controls .tenure-year{
  background-color: #F0F0F0;
  padding: 5px 10px;
}

.view-service-controls .tenure-year.selected{
  background-color: #d5d5d5;
}
.vehicle-service-container{
  margin: 10px 0;
  padding: 10px;
  background-color: #FFFFFF;
  border: 1px solid #E3E3E3;
  border-radius: 5px;
}