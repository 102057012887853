.login-container .contract-alert{
  text-align: center;
  color: #383881;
  font-size: 13px;
  cursor: pointer;
}
.login-container .contract-alert .icon{
  margin: 0 10px;
}

.principal-login-container {
  height: 100%;
  width: 100%;
  background-color: #f9f9f9;
  padding: 50px 0px;
}

.login-content-arrow {
  margin-top: 18px;
  margin-bottom: -50px;
}

.login-arrow-back {
  margin-left: 10%;
}

.login-content-title {
  text-align: center;
}

.login-content-title p {
  color: #434362;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 25px;
  font-weight: bold;
  line-height: 32px;
}

.login-content-title p>span {
  background: -webkit-linear-gradient(top, transparent 60%, #fdd44b 40%);
}

.login-content-text-mark {
  margin: -15px 0px 0px 325px;
}

.login-text-mark {
  width: 200px;
  height: 17px;
  background-color: #fdd44b;
  margin-top: -15px;
  z-index: 1;
  margin: 0px auto;
}

.login-form-container {
  margin: 40px auto 80px auto;
  text-align: center;
  width: 330px;
}

.login-content-input {
  margin: 20px 0px;
  position: relative;
}

.login-content-input .MuiFormHelperText-root {
  margin: 0;
  background-color: #f9f9f9;
  padding-top: 5px;
  text-align: right;
}

.login-content-input .MuiInputLabel-outlined {
  font-size: 15px;
}

.login-label-error {
  font-size: 11px;
  color: #ca0f4a;
  position: absolute;
  top: 60px;
  right: 0%;
}

.login-label-error img {
  margin-right: 5px;
}

.login-content-input .inputName .MuiFormHelperText-root {
  color: "#CA0F4A";
  text-align: "end";
}

.login-button-container {
  margin-top: 40px;
}

.login-find-alerts-container {
  position: absolute;
  right: -15%;
  top: 35%;
}

.login-find-alerts-p {
  color: #2c5d6e;
}

.login-find-alerts-p-title {
  font-size: 12px;
  font-weight: bold;
  line-height: 16px;
  margin-bottom: 5px;
  color: #2c5d6e;
}

.login-find-alerts-p-subtitle {
  font-size: 10px;
  font-weight: 400;
  line-height: 15px;
  color: #2c5d6e;
  margin-bottom: 15px;
}

.login-find-alerts-button {
  padding: 7px 14px;
  background-color: #b9d1db;
  border: solid #b9d1db;
  border-radius: 7px;
  font-size: 12px;
  font-weight: bold;
  line-height: 15px;
  color: #2c5d6e;
  cursor: pointer;
}

.div-test {
  width: 100%;
  border: solid;
  height: 40px;
  padding: 0px 50px;
}




.login-find-alerts-container {
  position: absolute;
  right: -190px;
  top: -32px;
  text-align: left;
}

/* Ipad */

@media (max-width: 769px) {
  .principal-login-container {
    text-align: center;
  }
  .login-container {
    background-image: none;
    background-color: #f9f9f9;
    height: auto;
    min-width: 300px;
  }
  .login-content-title {
    text-align: center;
  }
  .login-content-title p {
    color: #434362;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    font-size: 25px;
    font-weight: bold;
    line-height: 32px;
  }
  .login-content-text-mark {
    margin: -47px 35px 60px 0px;
  }
  .login-text-mark {
    width: 270px;
    height: 17px;
    background-color: #fdd44b;
    margin: 0px auto;
  }
  .login-content-input {
    margin: 20px 0px;
  }
  .login-content-input .MuiInputLabel-outlined {
    font-size: 15px;
  }
  .login-button-container {
    text-align: center;
  }
  .login-find-alerts-p {
    color: #2c5d6e;
  }
  .login-find-alerts-p-title {
    font-size: 12px;
    font-weight: bold;
    line-height: 16px;
    margin-bottom: 5px;
    color: #2c5d6e;
  }
  .login-find-alerts-p-subtitle {
    font-size: 10px;
    font-weight: 400;
    line-height: 15px;
    color: #2c5d6e;
    margin-bottom: 15px;
  }
  .login-find-alerts-button {
    padding: 5px 20px;
    background-color: #b9d1db;
    border: solid #b9d1db;
    border-radius: 7px;
    font-size: 10px;
    font-weight: bold;
    line-height: 15px;
    color: #2c5d6e;
    cursor: pointer;
  }
  .login-content-input {
    margin: 20px 0px;
    position: relative;
  }
  .login-label-error {
    font-size: 11px;
    color: #ca0f4a;
    position: absolute;
    top: 60px;
    right: 0%;
  }
  .login-label-error img {
    margin-right: 5px;
  }
  .login-button {
    color: #fff;
    width: 250px;
    height: 50px;
  }
  .login-button-disabled {
    width: 250px;
    height: 50px;
  }
  .login-button-progress {
    position: absolute;
    left: 80%;
    top: 10px;
  }
}