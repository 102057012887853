.foreign-vehicle-dialog-content {
    padding: 20px 60px;
    text-align: center;
    font-family: 'Roboto';
}

.foreign-vehicle-dialog-title {
    font-weight: bold;
    font-size: 15px;
}

.foreign-vehicle-dialog-subtitle {
    font-weight: 400;
    max-width: 250px;
    line-height: 25px;
    font-size: 13px;
    color: #434362;
}

.foreign-vehicle-dialog-button-container {
    padding-top: 40px;
}

@media (max-width: 768px) {
    .foreign-vehicle-dialog-content {
        padding: 30px;
    }
}