.basic-single-report-container {
  background-color: #ffffff;
  border: 1px solid #e3e3e3;
  border-radius: 5px;
  padding: 20px;
}

.basic-single-report-data {
  padding: 10px;
  background-color: #f8f8f8;
  border-radius: 5px;
  width: 100%;
  margin-right: 20px;
}

.basic-report-brand-img-container img {
  width: 100%;
}

.basic-report-vehicle-description {
  padding: 20px;
}

.basic-report-vehicle-brand {
  font-weight: bold;
  color: #444463;
  margin-bottom: 10px;
  font-size: 18px;
}

.basic-report-vehicle-plate {
  font-weight: bold;
  color: #17788d;
  margin-bottom: 20px;
  font-size: 16px;
}

.basic-report-vehicle-location {
  display: flex;
}
.basic-report-vehicle-location p {
  color: #707c89;
  font-size: 14px;
  margin: 0 10px;
}

.basic-report-service-container {
  background-color: #e9f2f4;
  border-radius: 5px;
  padding: 5px;
  font-size: 12px;
}
.basic-report-service-container.disabled {
  background-color: #efefef;
}

.basic-report-service-name {
  color: #646b72;
}

.basic-report-service-status {
  color: #444463;
  font-weight: bold;
}

.basic-report-service-icon-success {
  color: #17788d;
}

.basic-report-service-status.danger {
  color: #cc5656;
}

.basic-report-service-icon-danger {
  color: #e08686;
}

.basic-single-report-message {
  font-size: 10px;
  text-align: right;
  color: #abb2ba;
  margin: 10px 0;
  line-height: 16px;
}

@media (max-width: 769px) {
  .basic-single-report-data {
    margin-right: 0px;
    margin-bottom: 20px;
  }
}
