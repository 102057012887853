.payment-data-amount {
  color: #17788d;
  font-size: 30px;
  font-weight: bold;
  margin-right: 30px;
}

.payment-data-description {
  margin-top: 15px;
  margin-bottom: 5px;
  font-size: 12px;
  font-weight: bold;
  color: #434362;
}

.payment-data-iva {
  font-size: 10px;
  color: #707c89;
  margin-bottom: 15px;
}


.payment-data-coupon-container {
  margin-top: 20px;
}

.payment-data-coupon-title {
  font-weight: bold;
  font-size: 12px;
  margin-bottom: 10px;
}

.coupon-container {
  padding: 10px 20px;
  background-color: #e6e5ed;
  border-radius: 5px;
  color: #434362;
  margin: 0 -15px 30px -15px;
}

.coupon-title {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 5px;
}

.coupon-subtitle {
  font-size: 13px;
}

.coupon-container img {
  max-height: 60px;
  max-width: 100px;
}
