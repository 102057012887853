.vehicle-hologram-section {
  margin-top: 30px;
}

.vehicle-hologram-section .hologram-button {
  background-color: #F0F1F4;
  border: none;
  border-radius: 5px !important;
  margin-right: 10px;
  padding: 5px 20px;
  font-size: 14px;
  color: #434362;
}

.vehicle-hologram-section .hologram-button.Mui-selected {
  background-color: #FFF !important;
  outline: 3px solid #17788D;
}

.vehicle-hologram-section .hologram-input-selected .MuiOutlinedInput-notchedOutline {
  border: 3px solid #17788D !important;
}