.principal-confirm-code-container {
  height: 100%;
  width: 100%;
  background-color: #f9f9f9;
  padding: 50px 0px;
  text-align: center;
}

.confirm-code-content-title {
  position: relative;
}

.confirm-code-content-title p {
  color: #434362;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 25px;
  font-weight: bold;
  line-height: 32px;
}

.confirm-code-arrow-back {
  position: absolute;
  top: 50%;
  left: 10%;
}

.confirm-code-content-text-mark {
  margin: -15px 0px 20px 203px;
}

.confirm-code-text-mark {
  width: 150px;
  height: 17px;
  background-color: #fdd44b;
  margin-top: -15px;
  z-index: 1;
  margin: 0px auto;
}

.confirm-code-content-subtitle {
  text-align: center;
  margin-top: 20px;
}

.confirm-code-content-subtitle p {
  color: #434362;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: normal;
  line-height: 20px;
}

.confirm-code-content-subtitle p span {
  color: #434362;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: bold;
  line-height: 20px;
}

.confirm-code-form-container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: auto;
  margin-top: 40px;
  max-width: 300px
}

.confirm-code-form-content-code {
  padding: 5px 10px;
}

.confirm-code-form-content-code-loading {
  position: relative;
  top: 20px;
  right: 0px;
  left: 25px;
}

.confirm-code-form-progress {
  position: absolute;
  display: flex;
  align-items: center;
}

.confirm-code-form-content-image-done {
  position: absolute;
  display: flex;
  align-items: center;
  left: 40px;
  top: 5px;
}

.confirm-code-form-code {
  margin-left: 10px;
}

.confirm-code-validating-container {
  text-align: center;
}

.confirm-code-validating {
  position: relative;
  top: -46px;
  left: 162px;
}
.confirm-code-validating span {
  color: #383881;
}

.confirm-code-form-code input {
  width: 40px;
  height: 37px;
  padding: 5px;
  text-align: center;
  font-size: 18px;
  line-height: 20px;
  font-weight: 500;
  color: #383881;
}

.confirm-code-form-image-done {
  height: 15px;
}

.confirm-code-form-content-error-message {
  display: flex;
  justify-content: center;
  align-items: center;
}

.confirm-code-form-icon-error {
  margin-top: -25px;
  margin-left: 35px;
}

.confirm-code-form-error-message {
  font-size: 9px;
  font-weight: normal;
  line-height: 11px;
  color: #f44336;
  margin-left: 6px;
  margin-top: -25px;
}

.confirm-code-form-container-link {
  margin-top: 50px;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.confirm-code-form-link {
  font-size: 13px;
  color: #383881;
  line-height: 20px;
  font-weight: 700;
}

.confirm-code-form-img {
  margin-left: 10px;
}

/* Ipad */

@media (max-width: 769px) {
  .principal-confirm-code-container {
    text-align: center;
  }
  .confirm-code-container {
    background-image: none;
    background-color: #f9f9f9;
  }
  .confirm-code-content-title p {
    color: #434362;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    font-size: 25px;
    font-weight: bold;
    line-height: 32px;
  }
  .confirm-code-content-text-mark {
    margin: -17px 0px 0px 0px;
  }
  .confirm-code-text-mark {
    width: 150px;
    height: 17px;
    background-color: #fdd44b;
    margin-top: -15px;
    z-index: 1;
    margin: 0px auto;
  }
  .confirm-code-content-subtitle {
    margin-top: 35px;
  }
  .confirm-code-content-subtitle p {
    color: #434362;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
  }
  .confirm-code-content-subtitle p span {
    color: #434362;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    font-size: 14px;
    font-weight: bold;
    line-height: 20px;
  }
  .confirm-code-form-content-code {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .confirm-code-form-code {
    margin-left: 10px;
  }
  .confirm-code-form-code input {
    width: 40px;
    height: 37px;
    padding: 5px;
    text-align: center;
    font-size: 18px;
    line-height: 20px;
    font-weight: 500;
    color: #383881;
  }
  .confirm-code-form-code .md-input-focused {
    border: solid black;
  }
  .confirm-code-form-progress {
    margin-left: 10px;
    display: flex;
    align-items: center;
  }
  .confirm-code-form-content-image-done {
    margin-left: 15px;
    display: flex;
    align-items: center;
  }
  .confirm-code-form-image-done {
    height: 15px;
  }
  .confirm-code-form-content-error-message {
    margin-top: 25px;
  }
  .confirm-code-form-icon-error {
    margin-top: -25px;
    margin-left: 35px;
  }
  .confirm-code-form-error-message {
    font-size: 9px;
    font-weight: normal;
    line-height: 11px;
    color: #f44336;
    margin-left: 6px;
    margin-top: -25px;
  }
  .confirm-code-form-container-link {
    margin-top: 25px;
  }
  .confirm-code-form-link {
    font-weight: 500;
  }
}

/* Mobile */

@media (max-width: 400px) {}