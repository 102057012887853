.my-alerts-container {
  padding: 0 20px;
  max-width: 990px;
  margin: auto;
}

.my-alerts-container .note {
  color: #707C89;
  font-size: 10px;
  margin: 10px 0 60px 0;
  text-align: right;
}

.my-alerts-container .car-card {
  background-color: #ffffff;
  border: solid 1px #e3e3e3;
  border-radius: 6px;
  margin: 0 auto;
  padding: 15px;
  margin-bottom: 15px;
}

.my-alerts-container .car-card .animated.fadeOut {
  max-height: 0;
  /* -webkit-transition: max-height 1s;
  -moz-transition: max-height 1s;
  -o-transition: max-height 1s;
  transition: max-height 1s; */
}

.my-alerts-container .car-card .animated.fadeIn {
  max-height: 2000px;
}

.my-alerts-container .car-card .payment-pending-message {
  background-color: #F7EBF0;
  color: #CA0F4A;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
  font-size: 12px;
  font-weight: bold;
  text-align: right;
}

.my-alerts-container .contract-alert {
  text-align: center;
  color: #383881;
  font-size: 13px;
  cursor: pointer;
}

.my-alerts-container .contract-alert .icon {
  margin: 0 10px;
}

.my_alerts-view #footer-section{
  margin-bottom: 0;
}

@media (max-width: 769px) {
  footer.principal {
    margin-bottom: 0;
  }
}