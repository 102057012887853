.home-blog-container .title{
  font-style: normal;
  font-weight: bolder;
  font-size: 35px;
  color: #434362;
  margin: 60px 0;
  line-height: 40px;
  text-align: center;
}

.home-blog-container .subtitle{
  color: #434362;
  font-size: 16px;
  font-weight: 300;
  line-height: 25px;
  margin: auto;
  margin-bottom: 60px;
  text-align: center;
  max-width: 435px;
}
.link-container{
  margin-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.link-text {
  font-size: 13px;
  color: #383881;
  line-height: 20px;
  font-weight: 700;
  margin-right: 7px;
}