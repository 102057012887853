.home-container{
    max-width: 1050px;
    padding: 0 60px;
    margin: auto;
}
.home-container .content-button{
    text-align: center;
    margin: 90px 0;
}
@media (max-width: 769px) {
    .home-container{
        padding: 0 30px;
    }
}