.coupon-input-button{
  background-color: #D6D6DE;
  border: 1px solid #D6D6DE;
  padding: 9.5px 20px;
  margin-right: -13px;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  cursor: pointer;
  font-weight: bold;
  color: #52526F;
}
.coupon-input-button-loading{
  margin-right: 5px;
}

.coupon-input-success-container{
  background-color: #e9f0f2;
  border-radius: 5px;
  padding: 15px;
  text-align: center;
  font-size: 12px;
  color: #707C89;
  margin: 0 -15px;
  cursor: pointer;
}

.coupon-input-success-discount{
  color: #17788D;
  font-weight: bold;
}