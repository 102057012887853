.principal-insurance-container {
  height: 100%;
  width: 100%;
  background-color: #f9f9f9;
  padding: 60px 0px;
}

.mb-15 {
  margin-bottom: 15px;
}

.pl-10 {
  padding-left: 10px;
}

.insurance-container {
  min-height: 730px;
  width: 800px;
  margin: 0 auto;
}

.insurance-content-arrow {
  margin-top: 13px;
  margin-bottom: -45px;
}

.insurance-arrow-back {
  margin-left: -60%;
}

.insurance-content-title {
  text-align: center;
}

.insurance-content-title p {
  color: #434362;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 25px;
  font-weight: bold;
  line-height: 32px;
}

.insurance-content-subtitle {
  text-align: center;
  margin-bottom: 30px;
}

.insurance-content-subtitle p {
  color: #434362;
  font-family: "Roboto";
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.insurance-content-text-mark {
  margin: -17px 236px 20px 0px;
}

.insurance-text-mark {
  width: 90px;
  height: 17px;
  background-color: #fdd44b;
  margin-top: -15px;
  z-index: 1;
  margin: 0px auto;
}

.insurance-content-active {
  height: 80px;
  padding: 0 !important;
  margin: 0;
  cursor: pointer;
  background-color: white;
  border: solid #37c1e6;
  border-radius: 7px;
}

.insurance-content-inactive {
  height: 80px;
  padding: 0 !important;
  margin: 0;
  cursor: pointer;
  border: solid #f0f1f4;
  background-color: #f0f1f4;
  border: none;
  border-radius: 7px;
}

.insurance-title-images {
  text-align: center;
}

.insurance-title {
  font-family: "Roboto";
  text-align: center;
  font-size: 14px;
  line-height: 19px;
  font-weight: 500;
  color: #434362;
}

.insurance-title-images .card-img-car {
  padding-top: 13px;
  height: 25px;
  width: 40px;
}

.insurance-card-container {
  margin-top: 35px;
}

.insurance-email {
  width: 322px;
}

.insurance-email .MuiInputLabel-outlined {
  font-size: 15px;
}

.insurance-label-error {
  font-size: 11px;
  color: #ca0f4a;
  position: absolute;
  top: 75px;
  right: 0;
}

.insurance-label-error img {
  margin-right: 5px;
}

.insurance-card.active {
  box-shadow: none;
  background-color: #fff;
  border: solid #37c1e6;
}

.insurance-card.inactive {
  box-shadow: none;
  background-color: #f0f1f4;
}

.insurance-card:hover {}

.insurance-card-content {
  height: 80px;
  padding: 0 !important;
  margin: 0;
  cursor: pointer;
}

.insurance-card-title-images {
  text-align: center;
}

.insurance-card-title-images .card-img-car {
  margin-left: 60px;
  padding-top: 10px;
  height: 18px;
  width: 31px;
}

.insurance-card-title-images .card-img-icon {
  margin-left: 40px;
  padding-bottom: 7px;
  display: inline;
}

.insurance-data-contact {
  font-size: 11px;
  line-height: 15px;
  font-family: "Roboto";
  font-weight: 400;
  color: #707c89;
  margin: 25px 0px 0px 0px;
}

.insurance-card-title {
  font-family: "Roboto";
  text-align: center;
  font-size: 14px;
  line-height: 19px;
  font-weight: 500;
  color: #434362;
}

.insurance-card-text {
  font-family: "Roboto";
  text-align: center;
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  color: #707c89;
}

.insurance-button-container {
  text-align: center;
}

.insurance-button-container button {
  width: 250px;
}

.insurance-section-container.active {
  background-color: #fff;
  border: solid #37c1e6;
  position: relative;
  border-radius: 5px;
  padding: 20px 17px;
  margin: 15px 0px;
}

.insurance-section-container.inactive {
  background-color: #fff;
  border: none;
  position: relative;
  border-radius: 5px;
  padding: 20px 20px;
  margin: 15px 0px;
}

.insurance-search-plates {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 20px;
}

.insurance-search-plates .MuiInputBase-input {
  width: 50%;
}

.insurance-search-plates-button {
  position: absolute;
  left: 223px;
  top: 41px;
  height: 44px;
  background: #f0f1f4;
  border: solid #f0f1f4;
  border-radius: 3px;
  width: 110px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.insurance-search-plates-button-loading {
  position: absolute;
  left: 223px;
  top: 41px;
  height: 44px;
  background: #5a549d;
  border: solid #5a549d;
  border-radius: 3px;
  width: 110px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.insurance-search-plates-button-loading p {
  font-size: 14px;
  font-family: "Roboto";
  line-height: 19px;
  font-weight: 500;
  color: #fff;
  margin-right: 10px;
  margin-left: 10px;
}

.insurance-search-plates-button p {
  font-size: 14px;
  font-family: "Roboto";
  line-height: 19px;
  font-weight: 500;
  color: #434362;
  margin-right: 10px;
  margin-left: 10px;
}

.insurance-switch-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.insurance-switch-text {
  font-size: 11px;
  font-family: "Roboto";
  font-weight: 500;
  line-height: 15px;
  color: #707c89;
  margin-right: 10px;
}

.insurance-plates-container {
  margin-top: 20px;
}

.insurance-plates-content {
  margin: 10px 0px;
  display: flex;
  background-color: #f0fafc;
  height: 26px;
}

.insurance-plates-title {
  font-size: 12px;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 16px;
  color: #646b72;
  margin: auto 15px;
  width: 80px;
}

.insurance-plates-text {
  font-size: 12px;
  font-family: "Roboto";
  font-weight: bold;
  text-transform: uppercase;
  line-height: 18px;
  color: #444463;
  margin: auto 0;
}

.test .MuiOutlinedInput-input {
  padding: 5.5px 14px;
}

.insurance-verification-container {
  background-color: #fff;
  border: solid #37c1e6;
  position: relative;
  border-radius: 5px;
  padding: 0px 20px;
  margin-bottom: 20px;
}

.insurance-verification-content {
  margin: 0px 0px;
}

.insurance-verification-title {
  font-family: "Roboto";
  font-size: 11px;
  font-weight: 400;
  line-height: 15px;
  color: #707c89;
  margin: 10px 0px;
}

.insurance-verification-box.inactive {
  background: #f0f1f4;
  border: none;
  margin-right: 2px;
  margin-left: 2px;
  border-radius: 5px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.insurance-verification-box.active {
  background: #fff;
  border: solid #37c1e6 2px;
  margin-right: 2px;
  margin-left: 2px;
  border-radius: 5px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.insurance-verification-text {
  font-family: "Roboto";
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  color: #434362;
}

.insurance-select-content {
  padding-right: 10px;
}

.insurance-service-container {
  background-color: #fff;
  border: solid #37c1e6;
  position: relative;
  border-radius: 5px;
  padding: 0px 20px 0px 10px;
  margin-bottom: 20px;
}

.insurance-new {
  display: flex;
  align-items: center;
}

.insurance-new-title {
  font-family: "Roboto";
  font-size: 11px;
  font-weight: 400;
  line-height: 15px;
  color: #707c89;
  margin-right: 10px;
}

.insurance-service-container {
  background-color: #fff;
  border: solid #37c1e6;
  position: relative;
  border-radius: 5px;
  padding: 0px 20px 0px 10px;
  margin-bottom: 20px;
}

.insurance-insurance-container {
  background-color: #fff;
  border: solid #37c1e6;
  position: relative;
  border-radius: 5px;
  padding: 0px 20px 0px 20px;
  margin-bottom: 20px;
}

.insurance-button-container {
  margin: 30px 0px;
}

.insurance-button {
  width: 250px;
  height: 50px;
}

.insurance-button-disabled {
  width: 250px;
  height: 50px;
}

.insurance-button-progress {
  position: absolute;
  left: 75%;
  top: 10px;
}

.insurance-title-logos p {
  margin: 80px 0px 40px 0px;
  font-size: 18px;
  font-family: "Roboto";
  font-weight: 500;
  line-height: 30px;
  color: #434362;
}

.insurance-container-logo {
  height: 145px;
  display: flex;
  width: 145px;
  justify-content: center;
  align-items: center;
  background: white;
  margin-bottom: 18px;
}

.insurance-link {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 40px 0px 20px 0px;
}

.insurance-link p {
  font-size: 13px;
  font-family: "Roboto";
  font-weight: 500;
  line-height: 20px;
  color: #383881;
  margin-right: 10px;
}

.insurance-note {
  display: flex;
  justify-content: center;
  align-items: center;
}

.insurance-note p {
  font-size: 10px;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 17px;
  color: #707c89;
  text-align: center;
}

/* mobile */

@media (max-width: 769px) {
  .insurance-container {
    min-height: 730px;
    display: flex;
    justify-content: center;
  }
  .insurance-content-title {
    text-align: center;
  }
  .insurance-content-title p {
    color: #434362;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    font-size: 25px;
    font-weight: bold;
    line-height: 32px;
  }
  .insurance-content-text-mark {
    margin: -15px 0px 15px 0px;
  }
  .insurance-text-mark {
    width: 135px;
    height: 15px;
    background-color: #fdd44b;
    margin-top: -15px;
    z-index: 1;
    margin: 0px auto;
  }
  .insurance-section-cards {
    /* width: 100%;
    padding-top: 25px;
    min-height: 200px; */
    display: flex;
    margin-bottom: 10px;
    overflow: auto;
  }
  .insurance-card-container {
    /* width: 30%;
    margin-bottom: 15px;
    float: left;
    margin-right: 15px; */
    margin: 10px 5px 10px 5px;
  }
  .insurance-card-container:first-child {
    /* width: 30%;
    margin-bottom: 15px;
    float: left;
    margin-right: 15px; */
    margin: 10px 5px 10px 0px;
  }
  .insurance-card.active {
    box-shadow: none;
    background-color: #fff;
    border: solid #37c1e6;
  }
  .insurance-card.inactive {
    box-shadow: none;
    background-color: #f0f1f4;
  }
  .insurance-card:hover {}
  .insurance-card-content {
    height: 80px;
    width: 150px;
    padding: 0 !important;
    margin: 0;
    cursor: pointer;
  }
  .insurance-card-title-images {
    text-align: center;
  }
  .insurance-card-title-images .card-img-car {
    margin-left: 45px;
    padding-top: 10px;
    height: 18px;
    width: 31px;
  }
  .insurance-card-title-images .card-img-icon-aprove {
    margin-left: 25px;
    padding-bottom: 7px;
    display: inline;
  }
  .insurance-card-title-images .card-img-icon-declined {
    display: none;
    margin-left: 25px;
    padding-bottom: 7px;
  }
  .insurance-card-title {
    font-family: "Roboto";
    text-align: center;
    font-size: 14px;
    line-height: 19px;
    font-weight: 500;
    color: #434362;
  }
  .insurance-card-text {
    font-family: "Roboto";
    text-align: center;
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    color: #707c89;
  }
  .insurance-button-container {
    text-align: center;
  }
  .insurance-button-container button {
    width: 250px;
  }
  .insurance-section-container.active {
    background-color: #fff;
    border: solid #37c1e6;
    position: relative;
    border-radius: 5px;
    padding: 10px 20px;
    margin: 0px 30px;
  }
  .insurance-section-container.inactive {
    background-color: #fff;
    border: none;
    position: relative;
    border-radius: 5px;
    padding: 10px 20px;
    margin: 0px 30px;
  }
  .insurance-search-plates {}
  .insurance-search-plates .MuiInputBase-input {
    width: 50%;
  }
  .insurance-search-plates-button {
    position: absolute;
    left: 204px;
    top: 48px;
    height: 44px;
    background: #f0f1f4;
    border: solid #f0f1f4;
    border-radius: 3px;
    width: 110px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .insurance-search-plates-button p {
    font-size: 14px;
    font-family: "Roboto";
    line-height: 19px;
    font-weight: 500;
    color: #434362;
    margin-right: 10px;
    margin-left: 10px;
  }
  .insurance-search-plates-button-loading {
    position: absolute;
    left: 204px;
    top: 48px;
    height: 44px;
    background: #5a549d;
    border: solid #5a549d;
    border-radius: 3px;
    width: 110px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .insurance-search-plates-button-loading p {
    font-size: 14px;
    font-family: "Roboto";
    line-height: 19px;
    font-weight: 500;
    color: #fff;
    margin-right: 10px;
    margin-left: 10px;
  }
  .insurance-switch-container {
    display: flex;
    margin-right: 20px;
    align-items: flex-start;
  }
  .insurance-switch-text {
    font-size: 11px;
    font-family: "Roboto";
    font-weight: 500;
    line-height: 15px;
    color: #707c89;
    margin-right: 10px;
  }
  .insurance-plates-container {
    margin: 20px 0px;
  }
  .insurance-plates-content {
    display: flex;
  }
  .insurance-plates-content:nth-child(odd) {
    background-color: #f0fafc;
    height: 26px;
  }
  .insurance-plates-content:nth-child(even) {
    background-color: #fdfdfd;
    height: 26px;
  }
  .insurance-plates-title {
    font-size: 12px;
    font-family: "Roboto";
    font-weight: 400;
    line-height: 16px;
    color: #646b72;
    width: 100px;
    margin-left: 15px;
    margin: auto 15px;
  }
  .insurance-plates-text {
    font-size: 12px;
    font-family: "Roboto";
    font-weight: bold;
    text-transform: uppercase;
    line-height: 18px;
    color: #444463;
    margin: auto 0;
  }
  .insurance-content-subtitle {
    text-align: center;
    margin-bottom: 30px;
  }
  .insurance-content-subtitle p {
    color: #434362;
    font-family: "Roboto";
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }
  .test .MuiOutlinedInput-input {
    padding: 5.5px 14px;
  }
  .insurance-verification-container {
    background-color: #fff;
    border: solid #37c1e6;
    position: relative;
    border-radius: 5px;
    padding: 0px 20px;
    margin-bottom: 20px;
  }
  .insurance-verification-content {
    margin: 0px 0px;
  }
  .insurance-verification-title {
    font-family: "Roboto";
    font-size: 11px;
    font-weight: 400;
    line-height: 15px;
    color: #707c89;
    margin: 5px 0px;
  }
  .insurance-verification-box.inactive {
    background: #f0f1f4;
    border: none;
    margin: 0px 2px;
    border-radius: 5px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .insurance-verification-box.active {
    background: #fff;
    border: solid #37c1e6 2px;
    margin: 0px 2px;
    border-radius: 5px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .insurance-validation-box.inactive {
    background: #f0f1f4;
    border: none;
    margin: 0px 15px 0px 0px;
    border-radius: 5px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .insurance-validation-box.active {
    background: #fff;
    border: solid #37c1e6 2px;
    margin: 0px 15px 0px 0px;
    border-radius: 5px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .insurance-verification-text {
    font-family: "Roboto";
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
    color: #434362;
  }
  /* .insurance-select-content {
    margin: 0px 10px 0px 0px;
  } */
  .insurance-service-container {
    background-color: #fff;
    border: solid #37c1e6;
    position: relative;
    border-radius: 5px;
    padding: 0px 20px 0px 10px;
    margin-bottom: 20px;
  }
  .insurance-new {
    display: flex;
    align-items: center;
  }
  .insurance-new-title {
    font-family: "Roboto";
    font-size: 11px;
    font-weight: 400;
    line-height: 15px;
    color: #707c89;
    margin-right: 10px;
  }
  .insurance-service-container {
    background-color: #fff;
    border: solid #37c1e6;
    position: relative;
    border-radius: 5px;
    padding: 0px 20px 0px 10px;
    margin-bottom: 20px;
  }
  .insurance-insurance-container {
    background-color: #fff;
    border: solid #37c1e6;
    position: relative;
    border-radius: 5px;
    padding: 0px 20px 0px 20px;
    margin-bottom: 20px;
  }
  .insurance-button-container {
    margin: 50px 30px 0px 30px;
  }
  .insurance-content-button {}
  .insurance-button {
    width: 320px;
    height: 50px;
  }
  .insurance-button-disabled {
    width: 320px;
    height: 50px;
  }
  .insurance-button-progress {
    position: absolute;
    left: 70%;
    top: 10px;
  }
}