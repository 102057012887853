.payment-report-services-container{
  padding: 15px;
  border-radius: 5px;
  background-color: #f7f7f8;
  margin: 0 -15px;
}

.payment-report-services-container .success-icon {
  color: #17788d;
}

.payment-report-services-container span {
  color: #434362;
  font-size: 14px;
  font-weight: bold;
  margin-left: 5px;
}
