.introduction-banner {
  height: 450px;
  margin-left: auto;
}

.into-message {
  height: 100px;
  position: absolute;
  margin-top: -130px;
  margin-left: 300px;
}

.principal-introduction-organism {
  display: flex;
  padding-top: 75px;
  justify-content: space-between;
  margin-bottom: 90px;
}

/* .wrap-introduction-organism {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
} */

.wrap-introduction-organism .wrap-message {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.wrap-introduction-organism .wrap-message .content-message {
  max-width: 440px;
  margin-bottom: 24px;
}

.wrap-introduction-organism .wrap-actions {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  /* margin-top: -30px; */
}

.wrap-introduction-organism .wrap-actions .content-actions {
  display: flex;
}

.wrap-introduction-organism .wrap-actions .content-actions .pay {
  margin-top: 25px;
}

.wrap-introduction-organism .wrap-actions .content-actions .pay .pay-message {
  font-style: normal;
  font-family: "Roboto";
  font-weight: normal;
  font-size: 12px;
  color: #707c89;
  margin-top: 20px;
  font-weight: 400;
}

.wrap-introduction-organism .wrap-actions .content-actions .pay .pay-message span {
  color: #434362;
}

.wrap-introduction-organism .wrap-actions .content-actions .pay .pay-message span {
  font-weight: bolder;
}

.wrap-introduction-organism .wrap-actions .content-actions .play {
  display: flex;
  width: 100%;
  margin-top: 20px;
  /* padding-bottom: 35px; */
  /* align-items: center;
  justify-content: center; */
}

.wrap-introduction-organism .wrap-actions .content-actions .play img {
  width: 100px;
  height: auto;
  cursor: pointer;
  margin: 0px;
}

.wrap-introduction-organism .wrap-actions .content-actions .play p {
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  color: #434362;
  margin-left: 12px;
}

.wrap-introduction-organism .title {
  font-style: normal;
  font-weight: bold;
  font-size: 75px;
  color: #434362;
  margin-bottom: 24px;
  line-height: 85px;
  white-space: nowrap;
}

.wrap-introduction-organism .subtitle {
  font-style: normal;
  font-weight: 300;
  font-size: 27px;
  color: #434362;
  margin-bottom: 24px;
}

.wrap-introduction-organism .message {
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 26px;
  color: #434362;
  max-width: 475px;
  /* width: 52%; */
}

.principal-introduction-organism .wrap-modal-video {
  width: 50%;
}

.wrap-introduction-organism .animated.fadeOutRight {
  display: none;
}

.wrap-introduction-organism .animated.fadeInRight {
  display: block;
}

@media (min-width: 769px) {
  .wrap-introduction-organism .wrap-actions .content-actions .play {
    margin-top: 0px;
  }
}

@media (min-width: 1365px) {
  .wrap-introduction-organism .message {
    width: 100%;
  }

  /* .wrap-introduction-organism {
    width: 50%;
  } */
}

@media (max-width: 768px) {

  .introduction-banner {
    width: 100%;
    height: auto;
    margin-bottom: 20px;
    margin-left: -10px;
  }

  .into-message {
    height: 80px;
    position: absolute;
    margin-top: -110px;
    margin-left: 100px;
  }

  .pay-message {
    font-size: 13px;
  }

  /* .alerta-icon-container{
    display: block !important;
  } */
  /* article.principal .wrap-general {
    padding-top: 15px;
  } */
  .wrap-introduction-organism {
    display: block;
  }

  .wrap-introduction-organism .wrap-message {
    padding: 0;
    height: auto;
  }

  .wrap-introduction-organism .wrap-message .message {
    width: auto;
    /* padding: 0 10px;
    margin-left: 10px; */
  }

  .wrap-introduction-organism {
    width: 100%;
  }

  .arti .wrap-introduction-organism {
    width: 100%;
  }

  .principal-introduction-organism {
    margin-bottom: 90px;
    flex-direction: column;
    padding-top: 40px;
    /* display: block; */
  }

  .wrap-introduction-organism .title {
    font-size: 54px;
    margin-bottom: 19px;
    text-align: left;
    font-weight: 900;
    line-height: 65px;
    white-space: normal;
  }

  .wrap-introduction-organism .subtitle {
    font-size: 25px;
    margin-bottom: 20px;
    text-align: left;
    font-weight: lighter;
    line-height: 30px;
    margin-top: 30px;
  }

  .wrap-introduction-organism .message {
    font-size: 15px;
    line-height: 20px;
    width: 100%;
    font-weight: normal;
    text-align: left;
    /* margin-left: 10px; */
  }

  .wrap-message {
    padding-top: 15px;
  }

  /* ------ */
  .wrap-introduction-organism .wrap-actions .content-actions .play {
    margin: 0;
    justify-content: center;
  }

  .wrap-introduction-organism .wrap-actions .content-actions .play img {
    width: 100px;
    height: auto;
    margin: 0px -30px -25px -5px;
  }

  .wrap-introduction-organism .wrap-actions .content-actions .pay .button_atom {
    height: 47px !important;
    width: 300px !important;
    margin-left: 7px;
  }

  .wrap-introduction-organism .wrap-actions .content-actions .pay .button_atom p {
    font-size: 15px !important;
    font-weight: 400 !important;
  }

  .principal-introduction-organism .img-background {
    display: block;
  }

  .wrap-introduction-organism .wrap-actions {
    flex-direction: row-reverse;
    margin-top: 20px;
    padding: 0px;
  }

  .wrap-introduction-organism .wrap-actions :nth-child(1) {
    order: 3
  }

  .wrap-introduction-organism .wrap-actions :nth-child(2) {
    order: 1
  }

  .wrap-introduction-organism .wrap-actions :nth-child(3) {
    order: 2
  }

  .wrap-introduction-organism .wrap-actions .content-actions .pay .pay-message span:first-child {
    font-size: 13px;
    font-weight: bold;
    line-height: 18px;
  }

  .wrap-introduction-organism .wrap-actions .content-actions .pay .pay-message .message-cancel {
    font-size: 13px;
    font-weight: normal;
    line-height: 18px;
    color: #707070;
  }

  .wrap-introduction-organism .wrap-actions .content-actions {
    flex-direction: column-reverse;
    width: 100%;
    align-items: center;
    max-width: 500px;
    margin: 0 auto;
  }

  .banner_mobile_home {
    width: 100%;
  }
}