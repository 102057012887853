
.processing-services-container .service-container {
  padding: 20px;
  border-bottom: 1px solid #dedede;
}
.processing-services-container .service-container img {
  height: 19px;
  margin-right: 10px;
}

.processing-services-container .service-section-name {
  display: flex;
  align-items: center;
}
.processing-services-container .service-section-progress {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 20px;
}

.processing-services-container .service-section-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #17788d;
}
@media (max-width: 769px) {
  .processing-services-container .service-section-name {
    margin-bottom: 10px;
  }
}
