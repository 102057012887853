.verification-done-button-container{
    margin: 30px 0;
}
.verification-label{
    line-height: 20px;
    margin: 10px 0;
}
.verification-penality-section .service-main-title{
    margin: 20px -30px;
}
.verification-penality-section .verification-penality{
    font-size: 10px;
}

.verification-in-period{
    color: #D60013 !important;
}