.header-container.fixed-position {
  height: 122px;
}

.header-container header {
  background-color: #f9f9f9;
  text-align: center;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: top center;
  background-size: cover;
  /* border-bottom: 1px solid #ebebeb; */
  padding: 10px 0;
}

.header-container header nav {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.header-container.fixed-position header {
  background-color: #ffffff;
  position: fixed;
  width: 100%;
  z-index: 1199;
  animation: animatetop 0.5s;
}

.headband {
  background-color: #e6eff1;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -13px;
  margin-bottom: 10px;
}

.headband .logo-alerta-mi-casa {
  height: 16px;
  width: 13px;
  margin-right: 10px;
}

.headband span {
  font-family: "Roboto";
  font-size: 11px;
  font-weight: 400;
  line-height: 17px;
  color: #434362;
}

.headband strong {
  margin-left: 5px;
}

.headband .arrow {
  cursor: pointer;
  margin-left: 10px;
  top: 2px;
  position: relative;
}

.headband .close-alert {
  display: none;
}

.header-container .header-menu {
  max-width: 1050px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}

.header-agent-content {
  display: flex;
  justify-content: center;
  margin-bottom: -65px;
}

.header-agent-content img {
  margin-bottom: 26px;
  margin-top: -30px;
}

.header-container .header-menu .alerta_icon {
  width: 14.2em;
  height: 3.6em;
  margin-left: -40px;
}

.header-container .header-menu .menu {
  list-style: none;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: 100%;
  align-items: center;
  justify-content: flex-end;
}

.header-container.home-view .header-menu .hamburger-menu {
  display: none;
}

.header-container .header-menu .hamburger-menu {
  width: 26.531px;
  height: 17.688px;
  margin-left: 25px;
  cursor: pointer;
  padding: 10px;
  border-radius: 5px;
}

.header-container .header-menu .hamburger-menu:hover {
  background-color: #D3D3D3;
}

.header-container .header-menu .menu li {
  margin-left: 40px;
  cursor: pointer;
}

.header-container .header-menu .menu li.hamburger-menu-button {
  margin-left: 0;
}

.header-container .header-menu .menu li a {
  text-decoration: none;
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  color: rgba(67, 67, 98, 1);
}

.header-container .header-menu .menu li a.selected {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  color: #5c5ca5;
}

.header-container .header-menu .menu li.button a {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  border: 1px solid rgba(67, 67, 98, 1);
  color: rgba(67, 67, 98, 1);
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  padding: 6px 15px;
}

.header-container .header-menu .menu li.button a:hover {
  background-color: rgba(195, 199, 201, 0.3);
  border: 1px solid rgba(195, 199, 201, 0.3);
  color: rgba(0, 0, 0, 0.6);
}

.hamburger-menu-container .MuiDrawer-paper {
  margin-top: 10px;
  border-top-left-radius: 20px;
}

.hamburger-menu-container .MuiDrawer-paper>div {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.hamburger-menu-title {
  margin: 30px;
  padding-left: 20px;
}

.hamburger-menu-list {
  padding: 0 30px !important;
  height: 100%;
}

.hamburger-menu-list li {
  cursor: pointer;
}

.hamburger-menu-list {
  padding: 0 30px !important;
  height: 100%;
}

.hamburger-menu-list hr:last-child {
  display: none;
}

.hamburger-menu-container .alerta-logout-button {
  margin: 0 20px;
  color: #4A4A60;
  /* padding: 0 10px; */
  /* align-self: flex-end; */
  /* justify-self: flex-end; */
  background-color: #F4F4F4;
  margin-bottom: 100px;
}

@media (max-width: 768px) {
  .header-container header {
    padding-top: 20px;
    padding-bottom: 0px;
    /* z-index: 4000;
    padding-left: 0px !important;
    padding-right: 0px !important;
    padding-top: 1.2rem !important;
    padding-bottom: 1rem !important; */
  }
  /* .alerta-icon-container {
    display: none;
  } */
  .home-view .alerta-icon-container {
    display: block;
  }
  .headband {
    background-color: #e6eff1;
    height: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -18px;
    margin-bottom: 10px;
    padding: 5px 20px;
  }
  .headband .logo-alerta-mi-casa {
    height: 15px;
    width: 12px;
    align-self: start;
    margin-top: 5px;
  }
  .headband span {
    font-family: "Roboto";
    font-size: 12px;
    font-weight: 400;
    line-height: 17px;
    color: #434362;
    text-align: initial;
  }
  .headband .arrow {
    cursor: pointer;
    margin-left: 10px;
    width: 20px;
    height: 8px;
  }
  .headband .close-alert {
    display: block;
    cursor: pointer;
    color: #228397;
  }
  .header-container .header-menu .content-hamburger-menu {
    display: flex;
  }
  .header-container .header-menu .content-hamburger-menu>.menu {
    display: none;
  }
  .header-container.home-view .header-menu .hamburger-menu {
    display: block;
  }
  .header-container.home-view .back-container {
    display: none;
  }
  .header-container .header-menu {
    padding: 0 10px 10px 10px;
    justify-content: space-between;
  }
  .header-container .back-container {
    padding: 5px;
  }
  .header-container .header-menu .alerta_icon {
    width: 118px;
    height: 43px;
    margin-left: 0px;
  }
  .header-container .header-menu .my-alerts-button {
    display: none;
  }
  .header-container.home-view  .header-menu .my-alerts-button {
    display: list-item;
  }
  .header-container .header-menu .contract-alert-button {
    display: none;
  }
  .header-container .header-menu .hamburger-menu {
    margin-left: 10px;
  }
}