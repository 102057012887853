.single-report .estate-images {
  text-align: center;
}

.single-report .estate-images img {
  margin-left: 20px;
}
.single-report .btn-container {
  margin: 30px 0 10px 0;
  display: flex;
  align-items: flex-end;
}

.single-report .search-type-button {
  margin-right: 10px;
  display: inline-block;
  padding: 15px 40px;
  border-radius: 5px;
  background-color: #e6e6e6;
  font-size: 14px;
  font-weight: bold;
  color: #434362;
  border: none;
  cursor: pointer;
}
.single-report .search-type-button.selected {
  background-color: #D5D5D5;
}
.single-report .submit {
  margin-right: 10px;
  display: inline-block;
  padding: 20px 40px;
  border-radius: 5px;
  font-size: 14px;
  color: #434362;
  border: none;
  cursor: pointer;
  width: 100%;
  background-color: #f0f1f4;
  font-weight: 500;
}

.single-report .helper-niv{
  display: inline-block;
  margin-left: auto;
}

.single-report-niv-tooltip img{
  width: 100%;
}

.single-report .form-container {
  padding: 30px;
  background-color: #ffffff;
  border: 1px solid #e3e3e3;
  border-radius: 5px;
}

.single-report .message {
  font-size: 10px;
  color: #abb2ba;
  margin: 20px 0 40px 0;
  max-width: 500px;
  line-height: 14px;
}
.single-report .service-container {
  background-color: #dae2e3;
  border-radius: 5px;
  padding: 5px 10px;
  font-size: 12px;
}

.single-report .service-text {
  color: #434362;
}

.single-report .service-icon {
  color: #17788d;
}

@media (max-width: 769px) {
  .single-report .btn-container{
    flex-direction: column;
  }
  .single-report .btn-container button {
    width: 100%;
    margin-bottom: 10px;
    margin-right: 0;
    text-align: left;
  }
  .single-report .form-container {
    margin: 0px -20px;
    border-radius: 0px;
  }
}
