.steps-payment-oxxo-container {
  text-align: left;
}
.title-card-step {
  color: #434362;
  font-family: "Roboto";
  font-size: 12px;
  font-weight: 400;
  line-height: 46px;
  margin: -15px 0px;
}
.title-card-step span {
  font-family: "Roboto";
  font-weight: bold;
  line-height: 50px;
  color: #ffffff;
  background-position: center;
  padding: 7px 12px 7px 12px;
  background-repeat: no-repeat;
  margin-right: 10px;
}

@media (max-width: 400px) {
  .steps-payment-oxxo-container {
    text-align: left;
    padding: 0px 2%;
  }
}
