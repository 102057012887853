.home-principal-services-section {
  margin-bottom: 90px;
}

.home-principal-services-section-title {
  font-family: "Roboto";
  font-style: normal;
  color: #434362;
  line-height: 30px;
  text-align: left;
  font-size: 23px;
  margin: 60px 0;
}

.home-principal-services-section-card {
  background-color: #fff;
  border-radius: 5px;
  text-align: center;
  padding: 40px 0;
  box-shadow: 0px 0px 10px 2px #e7e7e794;
}

.home-principal-services-section-card .card-title {
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  color: #434362;
  text-align: center;
  line-height: 25px;
  margin: 20px 0;
}

.home-principal-services-section-card .card-text {
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  color: #707c89;
  line-height: 21px;
  text-align: center;
  margin: auto auto 20px;
  max-width: 216px;
}

.home-principal-services-section-card img {
  display: block;
  margin: 20px auto 20px auto;
  height: 56px;
  max-width: 60px;
}

.home-principal-services-section-card span {
  display: block;
  color: #434362;
  font-size: 13px;
  font-weight: bold;
}

.home-principal-services-section .footer-note {
  font-family: "Roboto";
  font-weight: 400;
  font-size: 12px;
  line-height: 25px;
  color: #707c89;
  margin: auto;
  max-width: 460px;
  margin-top: 50px;
}

.home-principal-services-section-card-info {
  background-color: #EEEEF5;
  border-radius: 5px;
  padding: 0 60px;
  box-shadow: 0px 0px 10px 2px #e7e7e794;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.home-principal-services-section-card-info .card-title {
  color: #434362;
  font-size: 17px;
  font-weight: 500;
  text-align: left;
  line-height: 25px;
}

.home-principal-services-section-card-info .card-text {
  color: #5A549D;
  font-size: 13px;
  font-weight: 300;
  margin: 10px 40px;
  display: flex;
  align-items: center;
  text-align: left;
  line-height: 25px;
}

.home-principal-services-section-card-info .card-text span {
  padding-left: 20px;
}

@media (max-width: 769px) {
  .home-principal-services-section {
    padding: 0px 20px;
  }

  .home-principal-services-section-card-info {
    padding: 20px 30px;
  }

  .home-principal-services-section-card-info .card-title {
    text-align: center;
  }

  .home-principal-services-section-card-info .card-text {
    text-align: center;
    margin: 30px 0px;
    flex-direction: column;
  }

  .home-principal-services-section-card-info .card-text span {
    padding-top: 10px;
    padding-left: 0;
  }
}