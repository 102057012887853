.blog-article {
    background-color: #ffffff;
    border-radius: 5px;
    width: 100%;
    cursor: pointer;
  }
  
  .blog-article-photo {
    height: 200px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    position: relative;
    overflow: hidden;
  }
  
  .blog-article-photo img {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    width: 100%;
    position: absolute;
    top: -9999px;
    bottom: -9999px;
    left: -9999px;
    right: -9999px;
    margin: auto;
  }
  
  .blog-article-title {
    font-size: 13px;
    font-weight: bold;
    line-height: 22px;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
    overflow: hidden;
  }
  
  .blog-article-subtitle {
    font-size: 13px;
    line-height: 22px;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
    overflow: hidden;
  }
  
  .blog-article-footer {
    padding: 15px 25px;
    color: #3E3E3E;
  }
  
  .blog-likes-container {
    color: #A8A8A8;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  
  .blog-article-likes {
    font-size: 10px;
  }
  
  .blog-article-date{
    color: #A8A8A8;
    font-size: 10px;
  }
  .blog-article-icon {
    margin-bottom: 5px;
  }