.doubts-page {
  padding: 0 18%;
  margin-bottom: 60px;
}

.principal-register-container {
  height: 100%;
  width: 100%;
  background-color: #f9f9f9;
  padding: 50px 0px;
}

.txt-center {
  text-align: center;
}

.txt-justify {
  text-align: justify;
}

.doubts-page .terms_conditions_text_block h4 {
  margin-top: 20px;
  margin-bottom: 0;
  font-style: normal;
  font-weight: bold;
  /* font-size: 27px; */
  color: rgba(67, 67, 98, 1);
}

.terms_conditions_text_block p {
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 26px;
  text-align: justify;
  color: rgba(67, 67, 98, 1);
}

.terms_conditions_text_block ul {
  margin-left: 50px;
}

.mrg-15 {
  margin-top: 15px;
}

.register-content-title {
  text-align: center;
  position: relative;
}

.register-content-title p {
  color: #434362;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 25px;
  font-weight: bold;
  line-height: 35px;
}

.register-content-title .register-arrow-back {
  position: absolute;
  top: 50%;
  left: 10%;
}

.register-content-text-mark {
  margin: -59px 0px 0px 45px;
}

.register-text-mark {
  width: 140px;
  height: 17px;
  background-color: #fdd44b;
  margin-top: -15px;
  z-index: 1;
  margin: 0px auto;
}

/* Ipad */

@media (max-width: 769px) {
  .principal-register-container {
    text-align: center;
  }
  .register-container {
    background-image: none;
    background-color: #f9f9f9;
  }
  .register-content-title {
    text-align: center;
  }
  .register-content-title p {
    color: #434362;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    font-size: 25px;
    font-weight: bold;
    line-height: 32px;
  }
  .register-content-text-mark {
    margin: -79px 0px 79px 80px;
  }
  .register-text-mark {
    width: 132px;
    height: 17px;
    background-color: #fdd44b;
    /* margin-top: -15px; */
    /* z-index: 1; */
    margin: 0px auto;
  }
  .register-content-buttons {
    margin: 0px 20%;
  }
}

/* Mobile */

@media (max-width: 500px) {
  .register-content-buttons {
    margin: 0px 50px;
  }
}