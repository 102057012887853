.error-card-payment-dialog-content {
    padding: 30px 40px;
    text-align: center;
    font-family: 'Roboto';
}

.error-card-payment-dialog-title {
    font-weight: bold;
}

.error-card-payment-dialog-subtitle {
    font-weight: 400;
    max-width: 315px;
    line-height: 25px;
    margin: auto;
}

.error-card-payment-dialog-button-container {
    padding-top: 40px;
}

@media (max-width: 768px) {
    .error-card-payment-dialog-content {
        padding: 30px;
    }
}