.single-report-loading-page .container {
  max-width: 650px !important;
}
.single-report-loading-page .service-container {
  padding: 20px;
  border-bottom: 1px solid #dedede;
}
.single-report-loading-page .service-container img {
  height: 19px;
  margin-right: 10px;
}

.single-report-loading-page .service-section-name {
  display: flex;
  align-items: center;
}
.single-report-loading-page .service-section-progress {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 20px;
}

.single-report-loading-page .service-section-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #17788d;
}
@media (max-width: 769px) {
  .single-report-loading-page .container {
    padding: 0px 20px;
  }
  .single-report-loading-page .service-section-name {
    margin-bottom: 10px;
  }
}
