.payment-page-container {
  max-width: 900px;
  margin: auto;
}

.payment-section {
  background-color: #ffffff;
  border: 1px solid #e3e3e3;
  border-radius: 5px;
  padding: 30px;
  width: 100%;
  height: fit-content;
}

.payment-section-title {
  color: #434362;
  font-weight: bold;
  font-size: 13px;
}

.payment-section-title-img {
  height: 30px;
}

.payment-footer-container {
  text-align: center;
  max-width: 450px;
  margin: 60px auto;
}

.payment-footer-img {
  margin: 5px;
  height: 25px;
}

.payment-footer-text {
  font-size: 12px;
  color: #707c89;
  line-height: 18px;
}

.payment-page-container.zero-amount {
  max-width: 500px;
}

.payment-page-container.zero-amount .payment-section {
  padding: 30px 60px;
}
.payment-page-container.zero-amount .payment-alerta-plates-container {
  padding: 15px 40px;
  margin: 0 -40px;
}

@media (max-width: 769px) {
  .payment-page-container {
    margin: 0 20px;
  }

  .payment-section {
    margin-bottom: 20px;
  }
}
